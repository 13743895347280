.app-zoom{
    // @font-face {
    //     font-family: 'productsansthinitalic';
    //     src: url('../fonts/productsansthinitalic.eot');
    //     src: url('../fonts/productsansthinitalic.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansthinitalic.svg#ProductSans-ThinItalic') format('svg'),
    //         url('../fonts/productsansthinitalic.ttf') format('truetype'),
    //         url('../fonts/productsansthinitalic.woff') format('woff'),
    //         url('../fonts/productsansthinitalic.woff2') format('woff2');
    // }

    // @font-face {
    //     font-family: 'productsansthin';
    //     src: url('../fonts/productsansthin.eot');
    //     src: url('../fonts/productsansthin.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansthin.svg#ProductSans-Thin') format('svg'),
    //         url('../fonts/productsansthin.ttf') format('truetype'),
    //         url('../fonts/productsansthin.woff') format('woff'),
    //         url('../fonts/productsansthin.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsansregular';
    //     src: url('../fonts/productsansregular.eot');
    //     src: url('../fonts/productsansregular.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansregular.svg#ProductSans-Regular') format('svg'),
    //         url('../fonts/productsansregular.ttf') format('truetype'),
    //         url('../fonts/productsansregular.woff') format('woff'),
    //         url('../fonts/productsansregular.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsansmediumitalic';
    //     src: url('../fonts/productsansmediumitalic.eot');
    //     src: url('../fonts/productsansmediumitalic.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansmediumitalic.svg#ProductSans-MediumItalic') format('svg'),
    //         url('../fonts/productsansmediumitalic.ttf') format('truetype'),
    //         url('../fonts/productsansmediumitalic.woff') format('woff'),
    //         url('../fonts/productsansmediumitalic.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsansmedium';
    //     src: url('../fonts/productsansmedium.eot');
    //     src: url('../fonts/productsansmedium.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansmedium.svg#ProductSans-Medium') format('svg'),
    //         url('../fonts/productsansmedium.ttf') format('truetype'),
    //         url('../fonts/productsansmedium.woff') format('woff'),
    //         url('../fonts/productsansmedium.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsanslightitalic';
    //     src: url('../fonts/productsanslightitalic.eot');
    //     src: url('../fonts/productsanslightitalic.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsanslightitalic.svg#ProductSans-LightItalic') format('svg'),
    //         url('../fonts/productsanslightitalic.ttf') format('truetype'),
    //         url('../fonts/productsanslightitalic.woff') format('woff'),
    //         url('../fonts/productsanslightitalic.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsansitalic';
    //     src: url('../fonts/productsansitalic.eot');
    //     src: url('../fonts/productsansitalic.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansitalic.svg#ProductSans-Italic') format('svg'),
    //         url('../fonts/productsansitalic.ttf') format('truetype'),
    //         url('../fonts/productsansitalic.woff') format('woff'),
    //         url('../fonts/productsansitalic.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsansbolditalic';
    //     src: url('../fonts/productsansbolditalic.eot');
    //     src: url('../fonts/productsansbolditalic.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansbolditalic.svg#ProductSans-BoldItalic') format('svg'),
    //         url('../fonts/productsansbolditalic.ttf') format('truetype'),
    //         url('../fonts/productsansbolditalic.woff') format('woff'),
    //         url('../fonts/productsansbolditalic.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsansbold';
    //     src: url('../fonts/productsansbold.eot');
    //     src: url('../fonts/productsansbold.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansbold.svg#ProductSans-Bold') format('svg'),
    //         url('../fonts/productsansbold.ttf') format('truetype'),
    //         url('../fonts/productsansbold.woff') format('woff'),
    //         url('../fonts/productsansbold.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsansblackitalic';
    //     src: url('../fonts/productsansblackitalic.eot');
    //     src: url('../fonts/productsansblackitalic.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansblackitalic.svg#ProductSans-BlackItalic') format('svg'),
    //         url('../fonts/productsansblackitalic.ttf') format('truetype'),
    //         url('../fonts/productsansblackitalic.woff') format('woff'),
    //         url('../fonts/productsansblackitalic.woff2') format('woff2');
    // }
    // @font-face {
    //     font-family: 'productsansblack';
    //     src: url('../fonts/productsansblack.eot');
    //     src: url('../fonts/productsansblack.eot?#iefix') format('embedded-opentype'),
    //         url('../fonts/productsansblack.svg#ProductSans-Black') format('svg'),
    //         url('../fonts/productsansblack.ttf') format('truetype'),
    //         url('../fonts/productsansblack.woff') format('woff'),
    //         url('../fonts/productsansblack.woff2') format('woff2');
    // }

    .bg-lights{
        background: white !important;
        height: 100px;
    }
    .Logobrand{
        height: 50px;
        margin-left: 70px;
    }
    .navbar-nav{
        font-size: 20px;
        margin-left: 220px;
    }
    .nav-rig{
        margin-right: 20px;
    }
    .profiles{
        margin-right: 60px;
    }
    .navbar.navbar-light .navbar-nav .nav-link.active{
        color: #4D148C !important;
        // font-weight: 600;
    }
    .navbar.navbar-light .navbar-nav .nav-link:hover{
        color: #4D148C !important;
        // font-weight: 500 !important;
    
    }
    .c-footer{
        color: white !important;
        background: #4D148C !important;
        border-top: 1px solid #d8dbe0;
    }
    /* The sidebar menu */
    .sidebar {
        height: 100%; /* 100% Full-height */
        width: 0; /* 0 width - change this with JavaScript */
        position: fixed; /* Stay in place */
        z-index: 1; /* Stay on top */
        top: 0;
        right: 0;
        background-color: #ECECEC; /* Black*/
        overflow-x: hidden; /* Disable horizontal scroll */
        padding-top: 60px; /* Place content 60px from the top */
        transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
    }
    
    /* The sidebar links */
    .sidebar .closebtn {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }
    
    .sidebar .closebtn:hover {
        color: #f1f1f1;
    }
    
    .sidebar .sidebar-log {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }
    
    .sidebar .sidebar-log:hover {
        color: #f1f1f1;
    }
    
    /* Position and style the close button (top right corner) */
    .sidebar .closebtn {
        position: absolute;
        top: 0;
        right: -45px;
        font-size: 36px;
        margin-right: 50px;
    }
    
    /* The button used to open the sidebar */
    .openbtn {
        font-size: 20px;
        cursor: pointer;
        background-color: #111;
        color: white;
        padding: 10px 15px;
        border: none;
    }
    
    .openbtn:hover {
        background-color: #444;
    }
    
    /* Style page content - use this if you want to push the page content to the right when you open the side navigation */
    #main {
        transition: margin-left .5s; /* If you want a transition effect */
        padding: 20px;
    }
    
    /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
    @media screen and (max-height: 450px) {
        .sidebar {padding-top: 15px;}
        .sidebar a {font-size: 18px;}
    } 
    .sideLogo{
        height: 60px;
    }
    .side-menu-t{
        margin-top: 40px;
        margin-left: 30px;
        font-size: 18px;
        color: #4D148C;
    }
    .side-menu{
        margin-top: 20px;
        margin-left: 30px;
        font-size: 18px;
        color: #4D148C;
    }
    .side-icon{
        color: #FF6600;
        font-size: 20px;
        margin-right: 10px;
    
    }
    .active-doc{
        font-size: 29px;
        font-weight: 700;
        color: #333269;
        //padding-top: 20px;  //commited 21th of nov 2022
        // padding-bottom: 20px;  //commited 21th of nov 2022
        padding-left: 25px;
        text-align: center;   //added 21th of nov 2022
    }
    .doc-integer{
        font-size: 39px;
        padding-bottom: 30px;
        text-align: center;
        font-weight: 700;
        color: #4D148C;
    }
    .doc-desc{
        text-align: center;
        font-size: 20px;
        color: #4D148C;
    }
    .doc-container{
        border: 2px solid #4D148C;
        background: white;
        padding-bottom: 50px;
        border-radius: 15px;
    }
    .prog-bar{
        height: 65px;
        padding-left: 40px;
        margin-bottom: 25px;
    }
    .upload-contanier{
        margin-top:20px;   
          
        .fmdoc-1 .file-input-label {
            font-size: 18px;
            font-weight: bold;
            background: #4D148C;
            color: lightgray;
            width: inherit;
        }
        input[type=file]{
            color: rgb(240, 240, 240);
        }
        input[type=file]::file-selector-button {
            border-radius: 10px;
            padding: 5px 40px;
            margin-left: 10px;
            background: #FF6600;
            border-color: #FF6600;
            color: white;
            font-size: 19px;
            cursor: pointer;
          }

                
        input[type=file]::file-selector-button:hover {
            background: #4D148C;
        }

        .fmdoc-1 .form-control-file {
            background: #F0F0F0;
            border-radius: 15px;
            outline: 3px dashed #7D8790;
            transition: outline-offset .15s ease-in-out, background-color .15s linear;
            padding-top: 16%;
            padding-bottom: 2%;
            -webkit-padding-start:41%;
            -moz-padding-start: 17%;
            text-align: center !important;
            margin: 0;
            width: 100% !important;
        }

        .fmdoc-1 .form-control-file:focus {
            outline: 3px groove #4D148C;
            -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
            transition: outline-offset .15s ease-in-out, background-color .15s linear;
        }

        .fmdoc-1 {
            position: relative
        }

        .fmdoc-1:after {
            pointer-events: none;
            position: absolute;
            top: 15%;
            left: 0;
            width: 12%;
            right: 0;
            height: 50%;
            content: "";
            background-image: url('../assets/images/upload.png');
            display: block;
            margin: 0 auto;
             background-size: 100%;
            background-repeat: no-repeat;
        }

        .fmdoc-1:before {
            position: absolute;
            left: 0;
            top: 50%;
            pointer-events: none;
            width: 100%;
            right: 0;
            height: 57px;
            content: "Drop Your Document Here";
            font-size: 28px;
            display: block;
            margin: 0 auto;
            // color: #000000;
            color: #FF6600;
            font-weight: 700;
            text-transform: capitalize;
            text-align: center;
        }
    }
    .drop-title{
        font-size: 28px;
        color: #FF6600;
        font-weight: 700;
        text-align: center;
    }
    .drop-btn{
        margin-left: 43%;
        margin-top: 30px;
        background: #FF6600;
        border-color: #FF6600;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: white;
        font-size: 19px;
    }
    .drop-contanier{
        background: #F0F0F0;
        border-radius: 0px 0px 15px 15px;
        margin-bottom: 30px;
        padding: 20px;
    }
    .sub-drop-contanier{
        padding-top: 100px;
        border: 3px dashed #7D8790;
        background: #E3F8FA;
        border-radius: 15px;
        padding-bottom: 200px;
    }
    .drop-img{
        height: 90px;
        margin-left:44%;
    }
    .drop-select{
        margin-left: 42%;
        margin-top: 10px;
        background: #F60;
        color: black;
        border-color: #F60;
        border-radius: 5px;
        padding-left: 11px;
        padding-right: 55px;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-top: 30px;
    
    }
    .dro-title{
        font-size: 28px;
        color: #000000;
        font-weight: 700;
        text-align: center;
    }
    .tiny-footer{
        background: #EBEBEB;
        height: 80px;
    }
    .check-input{
        margin-top: 39px;
        transform: scale(2);
        margin-right: 15px;
    }
    .tiny-footer-btn{
       // margin-right: 92px;  //commited on 25th nov
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-left: 30px;
        background: #FF6600;
        border-color: #FF6600;
        color: white;
        font-size: 19px;
        margin-top: 20px;
    }
    .recipt-contanier{
        padding-top: 100px;
        background: #E3F8FA;
        border-radius: 0px 0px 15px 15px;
        padding-bottom: 70px;
        margin-bottom: 30px;
    }
    .rec-form-container{
        background: white;
        margin-top: 85px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .rec-label{
        font-size: 19px;
        font-weight: 700;
        padding-top: 10px;
    }
    .rec-input{
        width: 80%;
        padding: 8px;
        background: #E7E7FF;
        border: 2px solid #F60 !important;
        border-radius: 7px;
    }
    .cus-btn{
        padding-top: 6px;
        padding-left: 10px;
        padding-right: 12px;
        padding-bottom: 9px;
        font-size: 21px;
        color: white;
        background: #4D148C;
        border-color: #4D148C;
        border-radius: 5px;
        margin-top: 40px;
        font-weight: 600;
    }
    .need-btn{
        padding-top: 5px;
        padding-left: 10px;
        padding-right: 30px;
        padding-bottom: 5px;
        font-size: 21px;
        color: #333269;
        background: #FF6600;
        border-color: #FF6600;
        border-radius: 5px;
        margin-top: 40px;
        font-weight: 600;
    }
    .submit-recipient{
        margin-top: 60px;
        padding-top: 10px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 10px;
        font-size: 20px;
        color: #4D148C;
    }
    .back-btn{
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-left: 30px;
        background: #FF2C00;
        border-color: #FF2C00;
        color: white;
        font-size: 19px;
        padding-right: 10px;
    }
    
    @media screen and (max-width: 480px) {
    
        .prog-bar{
            padding-left: 140px !important;
        }
        .drop-btn{
            margin-left: 25%;
        }
        .drop-img {
            margin-left: 30%;
        }
        .drop-select {
            margin-left: 22%;
        }
        .tiny-footer-btn{
            margin-right:0px;
        }
        .stmp-img{
            padding-left: 100px !important;
        }
        .sign-img{
            padding-left: 120px !important;
        }
        .card-resp{
            margin-top: 10px !important;
            margin-right: 10px !important;
        }
        .cross-btn{
            float: right;
            margin-top: -30px;
        }
        .apopt-cross-btn{
            margin-top: -65px;
        }
        .send-footer-btn{
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        .pri-txt{
            padding-left: 0px !important; 
        }
        .sig-name-field{
            width: 88% !important;
        }
        .sign-btn{
            margin-left: 30px;
        }
        .div-bottom-border{
            width: 18% !important;
        }
        .signature-img{
            margin-top: 20px;
        }
        .recipien-name-field{
            margin-left: 0px !important;
        }
        .full-name-txt{
            margin-left: 0px !important;
        }
        .req-txt{
            margin-left: 0px !important;
        }
        .confirm-txt{
            margin-left: 0px !important;
        }
        .table-contact{
            width: 88% !important;
        }
        .timeline-img{
            display: none;
        }
    }
    .tiny-bar{
        background: #C4C4C4;
        border-radius: 10px 10px 0px;
    }
    .progress-01{
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: 4px 0px 0px rgba(255, 102, 0, 0.65), 12px 0px 0px rgba(255, 102, 0, 0.24), 8px 0px 0px rgba(255, 102, 0, 0.2);
        border-radius: 10px 40px 40px 0px;
        background: linear-gradient(90deg, rgba(77, 20, 140, 0.83) 0%, rgba(101, 0, 214, 0.59) 100%);
    }
    .add-doc-bar{
        font-size: 20px;
        padding-left: 30px;
        color: white;
        font-weight: 600;
    }
    .progress-02{
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: 4px 0px 0px rgba(255, 102, 0, 0.65), 12px 0px 0px rgba(255, 102, 0, 0.24), 8px 0px 0px rgba(255, 102, 0, 0.2);
        border-radius: 0px 40px 40px 0px;
        background: linear-gradient(90deg, rgba(77, 20, 140, 0.83) 0%, rgba(101, 0, 214, 0.59) 100%);
    }
    .private-col{
        background: #4D148C;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 5px solid #F60;
        border-top: 1px solid #F60;
        border-left: 1px solid #F60;
        border-radius: 10px 10px 0px 0px;
    }
    .private-txt{
        font-size: 24px;
        color: white;
        font-weight: 600;
        padding-left: 20px;
    }
    .mess-txt{
        font-size: 16px;
        font-weight: 600;
        padding-top: 20px;
        padding-left: 20px;
    }
    .textarea-field{
        width: 95%;
        background: #E3F8FA;
        height: 170px;
        margin-top: 15px;
        margin-left: 10px;
        border: #E3F8FA;
        border-radius: 10px;
        color: #7D8790;
    }
    .textarea-blew-txt{
        font-size: 13px;
        padding-left: 10px;
        padding-top: 20px;
    }
    .done-btn{
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 20px;
        padding-right: 20px;
        background: #F60;
        border: #F60;
        color: white;
    }
    .cancel-btn{
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        background: white;
        border-color: #4D148C;
        margin-left: 10px;
    }
    .bell-img{
        margin-right:10px;
        height: 36px;
    }
    .profile-img{
        margin-right:5px;
        height: 45px;
    }
    .menu-btn{
        margin-left:20px;
    }
    .stmp-img{
        padding-left:65px;
    }
    .sign-img{
        padding-left:35px;
    }
    .num-txt{
        border-right:2px solid black;
    }
    .doc-upload-container{
        margin-top:50px;
        margin-bottom:50px;
        padding-top: 30px; 
        background: #F0F0F0;
        border-radius: 10px;
    }
    .foot-sign-txt{
        color:white;
    }
    .foot-eb-txt{
        color: orange;
        font-weight: 700;
    }
    .mt-40{
        margin-top: 40px;
    }
    .comment-section{
        background: white;
        padding-bottom: 40px;
        padding-top: 30px;
    }
    .progress-old{
        padding-top: 20px;
        padding-bottom: 20px;
        background: rgba(77, 20, 140, 0.83);
    }
    .pl-20{
        padding-left: 20px;
    }
    .timeline-img{
        height: 550px;
        margin-left: 120px;
    }
    .send-txt{
        font-size: 25px;
        font-weight: 700;
    }
    .send-desc{
        color:#7D8790;
    }
    .mt-52{
        margin-top: 52px;
    }
    .recip-txt{
        font-size: 25px;
        font-weight: 700;
    }
    .bulk-txt{
        font-size: 21px;
        font-weight:700;
    }
    .bulk-icon{
        color: #FF6600;
        font-size:24px;
    }
    .pl-30{
        padding-left: 30px;
    }
    .cus-option{
        background:#4D148C;
        font-size: 18px;
    }
    .tiny-foot-section{
        float: right;
        margin-top: 17px;
    }
    .back-icon{
        margin-left: 10px;
        margin-right: 12px;
    }
    .next-icon{
        margin-left: 10px;
        margin-right: 12px;
    }
    .drop-parent-option{
        text-align: center;
        font-size: 19px;
        color: #4D148C;
    }
    .drop-option{
        background:#CCCACA;
        font-size: 18px;
    }
    .next-drop-icon{
        margin-left: 100px;
        margin-right: 12px;
    }
    .recip-prof-img{
        height: 37px;
    }
    .mt-10{
        margin-top: 10px;
    }
    .org-border-row{
        border: 1px solid #FF6600;
        padding: 5px;
    }
    .blue-border-row{
        border: 1px solid #4D148C;
        padding: 5px;
    }
    .recipient-txt{
        font-size: 15px;
        padding-top: 10px;
        color: #4D148C;
        font-weight: 700;
    }
    .exp-txt{
        font-size: 22px;
        color: #000;
        font-weight: 600;
    }
    /*.private-txt{
        margin-top: 20px;
    }*/
    .envel-txt{
        font-size: 15px;
        color: #4D148C;
        font-weight: 600;
    }
    .calendar-input{
        width: 42%;
    }
    .num-input{
        width: 9%;
    }
    .day-txt{
        font-size: 15px;
        color: #F60;
        font-weight: 600;
    }
    .rec-pri-txt{
        margin-top: 50px;
        font-size: 22px;
        color: #000;
        font-weight: 600;
    }
    .ml-30{
        margin-left: 30px;
    }
    .allow-txt{
        font-size: 16px;
        color: #4D148C;
    }
    .mb-60{
        margin-bottom: 30px;
    }
    .about-section{
        background: white;
        padding-bottom: 20px;
    }
    .address-btn{
        margin-top: 30px;
        margin-left: 10px;
        background: rgba(255, 102, 0, 0.5);
        border-radius: 4px;
        border: rgba(255, 102, 0, 0.5);
        color: #4D148C;
        font-weight: 500;
        font-size: 16px;
    }
    .direct-btn{
        margin-top: 10px;
        margin-left: 10px;
        background: white;
        border-radius: 4px;
        color: black;
        font-weight: 500;
        font-size: 16px;
        border: white;
    }
    .border-style{
        border-left: 2px solid #4D148C;
    }
    .search-section{
        margin-left: -12px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #E3F8FA;
    }
    .search-field{
        margin-left: 6px;
        width: 98%;
        height: 25px;
        border-radius: 5px;
    }
    .ml-10{
        margin-left: 10px;
        font-size: 14px;
        color: #000;
        font-weight: 500;
    }
    .shot-section{
        background: #E3F8FA;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .shot-title{
        padding-top: 30px;
        font-size: 15px;
        color: #5B5C5C;
        font-weight: 600;
        border-bottom: 2px solid #F60;
        margin-left: 10px;
        line-height: 32px;
    }
    .mt-30{
        margin-top: 30px;
    }
    .recipt-title{
        font-size: 15px;
        font-weight: 600;
        color: #5B5C5C;
        padding-top: 10px;
    }
    .recipt-sub-title{
        font-size: 12px;
        color: #7D8790;
    }
    .keys-txt{
        float: right;
        padding-top: 15px;
        padding-bottom: 10px;
    }
    .shot-container{
        border-bottom: 2px solid #4D148C;
        line-height: 32px;
    }
    .shot-border-style{
        border-left: 2px solid #FF6600;
    }
    .sidebar .closebtn:hover{
        color: black;
    }
    .mr-10{
        margin-right: 10px;
    }
    .cross-btn{
        float: right;
    }
    .review-contanier{
        padding-top: 30px;
        background: #E3F8FA;
        border-radius: 0px 0px 15px 15px;
        padding-bottom: 25px;
        margin-bottom: 30px;
    }
    .review-title{
        font-size: 29px;
        font-weight: 600;
    }
    .recip-title{
        font-size: 19px;
        font-weight: 600;
    }
    .pri-txt{
        font-size: 18px;
        font-weight: 600;
        color: #215EE9;
    }
    .email-field{
        margin-top: 20px;
        font-size: 18px;
        font-weight: 700;
    }
    .email-input{
        width: 97%;
        height: 35px;
        border: #FFF;
        border-radius: 6px;
        color: #7D8790;
    }
    .email-mess-text{
        width: 97%;
        height: 170px;
        border: #FFF;
        border-radius: 6px;
        color: #7D8790;
        padding-top: 10px;
        padding-left: 10px;
    }
    .check-field{
        margin-top: 39px;
        transform: scale(1.5);
        margin-right: 15px;
        margin-left: 50px;
    }
    .review-select-parent{
        background: #F60;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-right: 10px;
        border-radius: 5px;
        border: #F60;
        margin-top: 20px;
        margin-left: 160px;
        color: #FFFF;
    }
    .review-select-child{
        background: #CCCACA;
        border: #CCCACA;
        color: #4D148C;
    }
    .foot-list{
        color: #215EE9;
        font-size: 19px;
        font-weight: 600;
    }
    .recip-file-name{
        font-size: 18px;
    }
    .uper-foot-txt{
        text-align: center;
        padding-bottom: 30px;
        font-size: 15px;
        color: #000;
    }
    .preview-btn{
        border: 1px solid #F60;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        color: #4D148C;
        font-size: 18px;
        font-weight: 600;
    }
    .m-20{
        margin-top: 20px;
    }
    .send-footer-btn{
        margin-right: 186px;
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-left: 30px;
        background: #FF6600;
        border-color: #FF6600;
        color: white;
        font-size: 19px;
    }
    .add-txt{
        font-size: 20px;
        padding-left: 13px;
    }
    .plus-icon{
        font-size: 20px;
    }
    .signature-list{
        background: #E0EDF7;
        padding-top: 30px;
        padding-bottom: 10px;
        margin-top: 15px;
        margin-bottom: 60px;
    }
    .signature-img{
        height: 40px;
    }
    .sign-check{
        transform: scale(2);
    }
    .font-txt{
        font-size: 19px;
        font-weight: 600;
        padding-left: 10px;
    }
    .sig-edt-txt{
        color: #215EE9;
        font-size: 18px;
    }
    .sub-adpt-text{
        color: #FFF;
        padding-left: 20px;
        font-size: 18px;
    }
    .ml-20{
        margin-left: 20px !important;
        margin-top: 30px;
    }
    .div-bottom-border{
        background: #4D148C;
        height: 5px;
        margin-top: 10px;
        width: 74%;
    }
    .checks-text{
        font-size: 17px;
        color: #4D148C;
    }
    .stamp-txt{
        margin-top: 20px;
        margin-left: 30px;
        font-size: 20px;
        color: #F60;
    }
    .limit-field-txt{
        margin-top: 30px;
        margin-left: 28px;
        font-size: 20px;
        color: #000;
        font-weight: 600;
    }
    .sig-name-field{
        margin-top: 10px;
        margin-left: 33px;
        width: 97%;
        height: 45px;
        border: #E3F8FA;
        background: #E3F8FA;
        border-radius: 5px;
        padding-left: 10px;
    }
    .sign-btn{
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 25px;
        padding-right: 25px;
        background: rgba(77, 20, 140, 0.5);
        border: rgba(77, 20, 140, 0.5);
        margin-top: 10px;
        color: #FFF;
        font-size: 18px;
        font-weight: 600;
    }
    .prog-bar-txt{
        font-size: 17px;
        color: #000;
    }
    .mt-80{
        margin-top: 80px;
    }
    .confirm-txt{
        font-size: 18px;
        font-weight: 600;
        margin-left: 20px;
    }
    .req-txt{
        font-size: 18px;
        font-weight: 600;
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .full-name-txt{
        font-size: 17px;
        font-weight: 600;
        margin-left: 20px;
    }
    .recipien-name-field{
        margin-left: 20px;
        width: 95%;
        height: 40px;
        background: #E3F8FA;
        border: #E3F8FA;
        border-radius: 5px;
        padding-left: 10px;
    }
    .initial-txt{
        font-weight: 600;
        font-size: 17px;
    }
    .initial-field{
        width: 95%;
        height: 40px;
        background: #E3F8FA;
        border: #E3F8FA;
        border-radius: 5px;
    }
    .recip-row{
        padding: 35px;
    }
    .rercip-list-name{
        font-size: 18px;
        font-weight: 600;
    }
    .recpt-row{
        padding: 35px;
        background: #E0EDF7;
    }
    .draw-box{
        height: 250px;
        text-align: center;
        padding-top: 210px;
        border: 4px solid #F60;
        border-radius: 10px;
        margin-top: 20px;
        color: #7D8790;
    }
    .term-txt{
        color: #7D8790;
        font-size: 15px;
    }
    .sub-upload-contanier{
        padding-top: 50px;
        border: 3px dashed #7D8790;
        background: #E3F8FA;
        border-radius: 15px;
        padding-bottom: 12px;
    }
    .upload-img{
        height: 90px;
        margin-left: 40%;
    }
    .upload-btn{
        color: #FFF;
        background: #F60;
        border: #F60;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 5px;
        margin-left: 41%;
        margin-top: 10px;
    }
    .format-text{
        text-align: center;
        color: #7D8790;
        font-size: 17px;
    }
    .div-upload-bottom-border{
        background: #4D148C;
        height: 5px;
        margin-top: 10px;
        width: 60%;
    }
    .apopt-cross-btn{
        float: right;
    }
    @media screen and (min-width: 481px) and (max-width: 900px){
        .div-bottom-border {
            width: 61px !important;
        }
        .sig-name-field{
            margin-left: 0px !important;
        }
        .limit-field-txt{
            margin-left: 0px !important;
        }
        .recipien-name-field{
            margin-left: 0px !important;
        }
        .full-name-txt{
            margin-left: 0px !important;
        }
        .timeline-img{
            display: none;
        }
        .drop-select{
            margin-left: 33%;
        }
    }
}



// #main {
//   margin: 50px 0;
// }

#main #faq .card {
  margin-bottom: 10px;
  border: 0;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
          box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #f2f0fb;
  color: #4D148C;
  padding: 12px;
}

#main #faq .card .card-header .btn-header-link:after {
//   content: "\f107";
//   font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

// #main #faq .card .card-header .btn-header-link.collapsed {
//   background: #f2f0fb;
//   color: #4d148c;
// }

// #main #faq .card .card-header .btn-header-link.collapsed:after {
// }

#main #faq .card .collapsing {
  background: #f2f0fb;
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #f2f0fb;
  line-height: 30px;
  color: #4D148C;
}

.register_title1{
    margin: 0.2rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: white;
}

.register_title2{
    margin: 0.2rem;
    font-size: 1.1rem;
    color: #F60;
}

.reg_Question{
     font-weight: bold;
    font-size: 1.2rem;
    color: #F60;
}


.accordian_cardBody{
    padding: 2% !important;
    text-align: justify;
    a {
        color: #FF6600;
        text-decoration: none;
    }

    a:hover {
        color: #FF6600;
        text-decoration: none;
    }
}

.upload_doc_cont{
    margin-left: -10px;
margin-right: -10px;
}


.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(77, 20, 140)'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(77, 20, 140)'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .accordion-button:focus{
    box-shadow: none !important;
  }

 .accordion-button:not(.collapsed) {
      box-shadow: inset 0 -1px 0 rgba(0,0,0,.125)!important;
    }

    .navbar.navbar-light .navbar-nav .nav-link{
        color: #4D148C;
    }

    .doc_padding{
        padding: 0rem 0rem;
    }
    .hyper-link{
        text-decoration: none;
    }

    .scrollbox{
        overflow: hidden;
    }

    .scrollbox:hover {
        overflow-y: scroll;
      }


      .pref_pad1{
        // padding: 0rem 1rem 0rem 1rem;
        padding: 0px 12px 0px 12px;
        margin-right: 93px;
        margin-left: 80px;
      }
      .container{
        max-width: 1200px !important;
      }

      .card-manage-bdy{
        height: 463px;
      }
      .sidebar-mng-1{
        height: 465px;
        width: 15.4% !important;
      }

      .temp-card-body{
        height: 423px;
        font-size: 14px;
      }

      .file-temp-icon{
        margin-right: 6px !important;
        vertical-align: middle !important;
      }