// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";


//zoomsignature 
.z-text-blue-dark {
    color: #4D148C !important
}

.z-text-orange-dark {
    color: #FF6600 !important
}

.z-text-gray-dark {
    color: #666 !important
}

.z-text-h-blue-dark:hover {
    color: #4D148C !important
}

.z-text-h-orange-dark:hover {
    color: #FF6600 !important
}

.z-text-h-gray-dark:hover {
    color: #666 !important
}


.z-text-gray-light {
    color: #EAEAEA !important
}


.z-bg-blue-dark {
    background-color: #4D148C !important
}

.z-bg-orange-dark {
    background-color: #FF6600 !important
}

.z-bg-gray-dark {
    background-color: #CCCACA !important
}

.z-bg-h-blue-dark:hover {
    background-color: #4D148C !important
}

.z-bg-h-orange-dark:hover {
    background-color: #FF6600 !important
}

.z-bg-h-gray-dark:hover {
    background-color: #CCCACA !important
}


.z-bg-gray-light {
    background-color: #EAEAEA !important
}

.z-br-blue-dark {
    border-color: #4D148C !important
}

.z-br-orange-dark {
    border-color: #FF6600 !important
}

.z-br-gray-dark {
    border-color: #CCCACA !important
}

.z-br-gray-light {
    border-color: #EAEAEA !important
}

.z-bg-red-dark {
    background-color: #F00 !important
}


.modal-primary .modal-header {
    border-bottom: 5px solid #CCCACA;
    border-radius: 5px;
}

.modal-success .modal-header {
    border-bottom: 5px solid #FF6600;
    border-radius: 5px;
}

.modal-danger .modal-header {
    border-bottom: 5px solid #CCCACA;
    border-radius: 5px;
}

.modal-secondary .modal-header {
    border-bottom: 5px solid #FF6600;
    border-radius: 5px;
}

.modal-info .modal-header {
    border-bottom: 5px solid #EAEAEA;
    border-radius: 5px;
}

.modal-warning .modal-header {
    border-bottom: 5px solid #4D148C;
    border-radius: 5px;
}


.paging_simple_numbers .current {
    background: #FF6600 !important;
    color: white !important;
}

.showLoading {
    opacity: 0.5;
}

.brandLogo {
    margin: 1rem 1rem 1rem 3rem !important;
    height: 60px;
}

#nabr {
    border-bottom: 4px solid #4D148C;
    background: white;
}

.mx-2 .c-avatar-img {
    width: 120% !important;
}

.text_print .viewer-text {
    color: black;
    opacity: 1;
}

.addNotification {
    width: 370px;
}

.addNotification .c-avatar .c-avatar-img {
    border: 1px solid #4D148C;
}

.addNotification .c-avatar .c-avatar-status {
    top: 30px;
    right: -1px
}

.addNotification {

    .dropdown-item.active,
    .dropdown-item:active {
        color: #4D148C;
        text-decoration: none;
        background-color: #eeeeee;
    }
}


.addNotification .dropdown-item .col-10 {
    line-height: 1.3,
}

.addNotification .border-bottom {
    border-bottom: 1px solid #4D148C !important;
    margin-left: 25% !important;
    margin-right: 25% !important;
    padding: 2px;
}

@media print {

    body,
    .viewer-inner-page {
        display: block !important;
        position: relative !important;
        width: auto !important;
        height: auto !important;
        overflow: visible !important;
        margin-left: 0 !important;
    }

    .text_print .viewer-text {
        color: black;
        opacity: 1;
    }
}

@media only screen and (max-width: 980px) and (min-width: 0px) {
    .display-sm-none {
        display: none !important;
    }
}

// pdf viewer
.viewer-layout-with-sidebar.viewer-layout-container {
    grid-template-columns: 100% !important;
}

.viewer-layout-main {
    overflow-y: scroll !important;
    /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.viewer-layout-main::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.viewer-layout-main {
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
}

.viewer-layout-sidebar .viewer-sidebar-tabs {
    display: none;
}

.viewer-sidebar-content {
    padding: 10px !important;
    display: flow-root !important;
    margin: 0px 5px 0px 0px;
    overflow-y: scroll !important;
    /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.viewer-sidebar-content::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.viewer-sidebar-content {
    -ms-overflow-style: none !important;
    /* IE and Edge */
    scrollbar-width: none !important;
    /* Firefox */
}

.viewer-thumbnail-selected {
    background-color: #eeeeee !important;
    border-radius: 5px;
    margin: 2px 0px;
}

.viewer-thumbnail:hover {
    background-color: #eeeeee !important;
    border-radius: 5px;
}

.viewer-layout-container-row {
    display: flex;
}

.viewer-toolbar-current-page-input {
    text-align: center !important;
    width: 30px !important;
}

.viewer-layout-sidebar {
    width: 10% !important;
    //display: contents !important;
}


.container-fluid {
    padding: 0px !important;
}

.not-allowed {
    cursor: not-allowed !important;
}

.cursor-pointer {
    cursor: pointer;
}

.d-contents {
    display: contents !important;
}

.d-grid {
    display: grid;
}

.align-self-center {
    align-self: center;
}

.height-fit-content {
    height: fit-content;
}

.text-danger {
    color: red !important;
}

.text-success {
    color: green !important;
}

.text_danger {
    color: red !important;
}

.text_success {
    color: green !important;
}

.bg-danger {
    background: red !important
}

.bg-success {
    background: green !important
}

.bg_danger {
    background-color: red !important
}

.bg_success {
    background-color: green !important
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;
}

.br-2 {
    border-width: 1px;
    border-style: solid;
}

.form-control {
    font-size: 1rem;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.display-inline-block {
    display: inline-block;
}

.text-transform-capitalize {
    text-transform: capitalize;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text-transform-lowercase {
    text-transform: lowercase;
}

.small,
small {
    font-size: 80% !important;
}

.border-radius-0 {
    border-radius: 0px !important;
}

.modal-content {
    border-radius: 10px !important;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-right-auto {
    margin-right: auto;
}

.text-dngr {
    color: red
}

.text-suces {
    color: green
}

.br-0 {
    border: 0px solid !important;
}

.brl-0 {
    border-left: 0px solid !important;
}

.brr-0 {
    border-right: 0px solid !important;
}

.auth-left-card {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.auth-right-card {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.page-header {
    height: 320px;
    background-position: center;
    // background-image: url("images/backn1.jpeg");
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
}

.header-filter:after,
.header-filter:before {
    position: inherit;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
}

// .nvbtn-1{margin-top: 0.5rem;}
.topNav .d-flex {
    align-self: center;
}

.topNav li {
    align-self: center;
}

.topNav .drop-1 {
    margin-top: 3%;
}

.main-raised {
    margin: -70px 30px 0;
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
}

.main {
    background: #FFF;
    position: relative;
    z-index: 3;
}

// sidebar
.sidebarShow {
    width: 250px !important;
}

.app-zoom {
    .navbar.navbar-light .navbar-nav .nav-link.active {
        color: #FF6600 !important;
        // font-weight: 600;
    }

    .navbar.navbar-light .navbar-nav .nav-link:hover {
        // text-decoration: revert;
        text-decoration-color: #FF6600;
    }

    // .navbar-nav {
    //     padding-bottom: 10px;
    //     margin-left: auto !important;
    // }

    .profl-1 {
        display: -webkit-inline-box;
    }

    .profl-1 .menu-btn {
        margin-top: 12px;
        margin-left: 10px;
    }

    .profl-1 .c-header-nav-item {
        margin-right: 5px;
    }

    .profiles {
        display: -webkit-inline-box;
    }

    .profile-img {
        height: 50px !important;
        border: 3px solid #FF6600;
        background-color: #4D148C;
        padding: 2px;
        border-radius: 50%;
        margin-left: 10px;
    }

    .c-header-nav-link {
        padding-right: 1.5rem;
    }

    .c-header-nav-link .notificationItemsCount {
        position: absolute;
    }

    .bg-lights {
        height: 100px !important;
    }

    .bell-img {
        margin-right: -10px !important;
    }

    .prof-name {
        color: blue;
        font-size: 18px;
        margin: 0px;
        margin-top: 10px;
        font-weight: bold;
        margin-left: 5px;
    }

    .prof-name-1 {
        text-decoration: none !important;
        margin-right: 5px;
    }

    .prof-name:hover {
        // text-decoration: underline;
        text-decoration-color: #FF6600;
    }

    .sidebar {
        z-index: 99999;
    }

    .sidebar .closebtn:hover {
        color: #FF6600;
    }

    .side-menu-t {
        margin-left: 20px;
        cursor: pointer;

        svg {
            width: 2.5rem;
            color: #FF6600;
        }

        a {
            color: #4D148C;
            text-decoration: none;
        }
    }

    .side-menu {
        margin-left: 20px;
        cursor: pointer;

        svg {
            width: 2.5rem;
            color: #FF6600;
        }

        a {
            color: #4D148C;
            text-decoration: none;
        }
    }

    .side-menu-t:hover {
        color: #FF6600;
        // font-weight: bold;
    }

    .side-menu:hover {
        color: #FF6600;
        // font-weight: bold;
    }

    .foot-sign-txt {
        text-decoration: none;
        font-weight: bold;
    }

    .foot-sign-txt:hover {
        // text-decoration: underline;
        text-decoration-color: #FF6600;
        color: #FF6600;
    }


    //dashboard
    .upload-contanier {
        margin-bottom: 30px;
    }

    .doc-container {
        // padding-bottom: 25px !important;   //commited 21th of nov 2022
        padding-bottom: 1% !important;  //added 21th of nov 2022
    }

    .doc-integer {
        padding: 10px 0px 20px 0px !important;
    }

    .doc-signature {
        padding-left: 30px !important;
        padding-right: 0px !important;
    }

    .pro-img {
        height: 120px;  //added 21th of nov 2022
        // height: 150px;  //commited 21th of nov 2022
        border: 2px solid #FF6600;
        border-radius: 50%;
        padding: 3px;
        background: #4D148C;
    }

    .prog-2 {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @keyframes growProgressBar {

            0%,
            33% {
                --pgPercentage: 0;
            }

            100% {
                --pgPercentage: var(--value);
            }
        }

        @property --pgPercentage {
            syntax: '<number>';
            inherits: false;
            initial-value: 0;
        }

        div[role="progressbar"] {
            --size: 5rem;
            --fg: #FF6600;
            --bg: #EAEAEA;
            --pgPercentage: var(--value);
            margin-bottom: 10px;
            animation: growProgressBar 3s 1 forwards;
            width: var(--size);
            height: var(--size);
            border-radius: 50%;
            display: grid;
            place-items: center;
            background:
                radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
                conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
            font-family: Helvetica, Arial, sans-serif;
            font-size: calc(var(--size) / 5);
            color: var(--fg);
        }

        div[role="progressbar"]::before {
            counter-reset: percentage var(--value);
            content: counter(percentage) '%';
            font-size: 24px;
            text-align: center;
            font-weight: 700;
            color: #4D148C;
            padding: 5px 0 0 5px;
        }
    }

    @media screen and (max-height: 450px) {
        .num-txt {
            border: 0px;
        }
    }

    .drop-btn {
        border-radius: 10px;
    }

    .drop-btn:hover {
        background-color: #4D148C;
        border-color: #4D148C;
    }

    .prog-bar {
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding: 0px !important;
    }

    .drop-contanier {
        margin-bottom: 4%;
    }

    .progress-01 {
        padding: 10px 0px;
    }

    .progress-temp {
        align-self: center;
        text-align: center;

        .btn {
            font-weight: bold;
            color: #4D148C;
        }

        .btn:hover {
            background: #FF6600;
            color: #C4C4C4;
        }
    }

    .progress-old1 {
        border-top-left-radius: 10px
    }

    .progress-old {
        padding: 10px 0px;
    }

    .progress-02 {
        padding: 10px 0px;
    }

    .progress-04 {
        border-radius: 00px 10px 0px 0px;
    }

    .env_add_doc {

        // envelop add document
        .fm-1 .file-input-label {
            font-size: 18px;
            font-weight: bold;
            padding: 0.5rem 1rem;
            background: #4D148C;
            color: lightgray;
            width: inherit;
            margin: 1.25rem 0.5rem 0.2rem;
        }

        .fm-1 .form-control-file {
            background: #F0F0F0;
            border-radius: 15px;
            outline: 3px dashed #7D8790;
            outline-offset: -10px;
            transition: outline-offset .15s ease-in-out, background-color .15s linear;
            // padding: 350px 0px 140px 42%;
            padding-top: 16%;
            padding-bottom: 2%;
            -webkit-padding-start:45%;
            -moz-padding-start: 14%;
            text-align: center !important;
            margin: 0;
            width: 100% !important;
        }

        .fm-1 .form-control-file:focus {
            outline: 3px groove #4D148C;
            outline-offset: -10px;
            -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
            transition: outline-offset .15s ease-in-out, background-color .15s linear;
        }

        .fm-1 {
            position: relative
        }

        .fm-1:after {
            pointer-events: none;
            position: absolute;
            top: 15%;
            left: 0;
            width: 12%;
            right: 0;
            height: 50%;
            content: "";
            background-image: url('../assets/images/upload.png');
            display: block;
            margin: 0 auto;
             background-size: 100%;
            background-repeat: no-repeat;
        }

        .fm-1:before {
            position: absolute;
            left: 0;
            top: 50%;
            pointer-events: none;
            width: 100%;
            right: 0;
            height: 57px;
            content: "Drop Your Document Here";
            font-size: 28px;
            display: block;
            margin: 0 auto;
            color: #FF6600;
            font-weight: 700;
            text-transform: capitalize;
            text-align: center;
        }

        .fm-2 {
            background: #F0F0F0;
            border-radius: 15px;
            outline: 3px dashed #7D8790;
            outline-offset: -10px;
            transition: outline-offset .15s ease-in-out, background-color .15s linear;
            padding: 20px 0px;
            text-align: center !important;
            margin: 0;
            width: 100% !important;

            .fl-1 {
                height: 7rem;
                width: 7rem;
                color: #4D148C;
            }

            h4 {
                // margin: 2rem;
                color: #666666;
            }

            .tiny-fileUp-btn {
                font-size: 20px;
                border-radius: 10px;
                padding: 10px 40px;
                margin-left: 10px;
                color: #ffffff;
                background-color: #FF6600;
                margin-right: auto;

                svg {
                    padding-top: 3px;
                }
            }

            .tiny-fileCh-btn:hover {
                background-color: #4D148C;
            }

            .fil-1 {
                margin-left: 10px;
                font-size: 24px;
            }

            .tiny-fileCh-btn {
                font-size: 20px;
                border-radius: 10px;
                padding: 10px 40px;
                color: #ffffff;
                background-color: #666666;
                margin-left: auto;
                margin-right: auto;

                svg {
                    padding-top: 3px;
                }
            }

            .tiny-fileUp-btn:hover {
                background-color: #4D148C;
            }

            .fil-2 {
                margin-left: 10px;
                font-size: 24px;
            }
        }

        .fm-3 .progress-bar {
            background-color: #FF6600 !important;
        }
    }

    // envelop add recipient
    .env_add_recp {
        padding: 30px 0px !important;

        .rec-form-container {
            margin-top: 20px;
            padding-top: 0px;
            padding-bottom: 0px;
        }

        .bl-1 {
            border-left: 2px solid #4D148C;
            padding: 0px 20px;

            .row {
                margin: 0px;
            }


            .time-icn {
                top: 0;
                left: -45px;
                position: relative;
                width: 50px;
                background: #FF6600;
                padding: 10px;
                padding-left: 2px;
                height: 0%;
                border-radius: 50px;
                border: 2px solid #4D148C;

                svg {
                    height: 1.2rem;
                    color: #E3F8FA;
                    width: 2.5rem;
                }
            }

            .time-icn-recpt {
                top: 100px;
                left: -33px;
                position: relative;
                width: 50px;
                padding: 10px;
                padding-left: 2px;
                height: 0%;
                border-radius: 50px;

                svg {
                    height: 1.5rem;
                    background-color: #4D148C;
                    color: #4D148C;
                    width: 1.2rem;
                    border-top: 2px solid #E3F8FA;
                    border-bottom: 2px solid #E3F8FA;
                }
            }

            .time-icn-recpt-temp {
                top: 60px !important;
            }

            // use in template

            .time-icn-close {
                background: #FF6600;
                border: 2px solid #4D148C;
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                float: right;
                right: -20px;
                top: -20px;

                svg {
                    margin-top: 2px;
                    width: 0.8rem;
                    color: #E3F8FA;
                    height: 2rem;
                    margin-left: -4px;
                }
            }

            .btm-cor {
                padding-top: 10px;
                position: absolute;
                font-weight: bold;
                color: #666;
            }

            // recipient list
            .recpt_list {
                .rec-input {
                    width: 100%;
                    height: 50px;
                    background: #E3F8FA;
                }

                .rec-input:disabled {
                    background: #eee;
                    cursor: not-allowed;
                }

                .recpt_list_row {
                    margin: 10px 0px;
                }

                * {
                    scrollbar-width: thin;
                    scrollbar-color: #BBB #EEE;
                }

                *::-webkit-scrollbar {
                    width: 10px;
                }

                *::-webkit-scrollbar-track {
                    background: #C0C3C6;
                }

                *::-webkit-scrollbar-thumb {
                    background-color: #888;
                    border-radius: 10px;
                    border: 3px solid #C0C3C6;
                }

                input[list]:focus {
                    outline: none;
                }

                input[list]+div[list] {
                    display: none;
                    position: absolute;
                    width: 100%;
                    max-height: 164px;
                    overflow-y: auto;
                    max-width: 330px;
                    background: #FFF;
                    border: var(--border);
                    border-top: none;
                    border-radius: 0 0 5px 5px;
                    box-shadow: 0 3px 3px -3px #333;
                    z-index: 100;
                }

                input[list]+div[list] span {
                    display: block;
                    padding: 7px 5px 7px 20px;
                    color: #4D148C;
                    font-weight: bold;
                    text-decoration: none;
                    cursor: pointer;
                }

                input[list]+div[list] span:not(:last-child) {
                    border-bottom: 1px solid #EEE;
                }

                input[list]+div[list] span:hover {
                    background: rgba(100, 120, 140, .2);
                }


                .recpt_list_0 .recpt_row {
                    border-left: 8px solid #f5c431;

                    .rec-input {
                        border: 2px dashed #f5c431 !important;
                    }
                }

                .recpt_list_1 .recpt_row {
                    border-left: 8px solid #81afb8;

                    .rec-input {
                        border: 2px dashed #81afb8 !important;
                    }
                }

                .recpt_list_2 .recpt_row {
                    border-left: 8px solid #91789e;

                    .rec-input {
                        border: 2px dashed #91789e !important;
                    }
                }

                .recpt_list_3 .recpt_row {
                    border-left: 8px solid #70948d;

                    .rec-input {
                        border: 2px dashed #70948d !important;
                    }
                }

                .recpt_list_4 .recpt_row {
                    border-left: 8px solid #e69d73;

                    .rec-input {
                        border: 2px dashed #e69d73 !important;
                    }
                }

                .recpt_list_5 .recpt_row {
                    border-left: 8px solid #6c89a6;

                    .rec-input {
                        border: 2px dashed #6c89a6 !important;
                    }
                }

                .recpt_list_6 .recpt_row {
                    border-left: 8px solid #aeb86e;

                    .rec-input {
                        border: 2px dashed #aeb86e !important;
                    }
                }

                .recpt_list_7 .recpt_row {
                    border-left: 8px solid #db765c;

                    .rec-input {
                        border: 2px dashed #db765c !important;
                    }
                }

                .recpt_list_8 .recpt_row {
                    border-left: 8px solid #b38fb3;

                    .rec-input {
                        border: 2px dashed #b38fb3 !important;
                    }
                }

                .recpt_list_9 .recpt_row {
                    border-left: 8px solid #d98298;

                    .rec-input {
                        border: 2px dashed #d98298 !important;
                    }
                }


                .recip-dropdown {
                    max-height: 50px;
                    text-align: end;

                    .submit-recipient {
                        background: #4D148C;
                        border-radius: 10px;
                        border: 1px solid white;
                        color: white;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        height: 50px;
                        margin-top: 45px !important;
                        margin-left: 2%;
                        width: 250px;
                    }

                    .dropdown-toggle {
                        width: 80%;
                        // display: inline-flex;                        
                        background: #4D148C;
                        border-radius: 10px;
                        border: 1px solid white;
                        color: white;
                        margin-bottom: 8px;
                        margin-top: 50px !important;

                        span {
                            margin-right: 10px;
                        }
                    }

                    .fontawesome-select {
                        font-family: 'FontAwesome', 'Helvetica';
                    }

                    .dropdown-toggle::after {
                        margin-left: 0.300em;
                        font-size: 20px;
                        margin-right: 0.300rem;
                        margin-top: 0.5rem;
                    }

                    .dropdown-toggle-option {
                        width: 80%;
                        // display: inline-flex;                        
                        background: #FF6600;
                        border-radius: 10px;
                        border: 1px solid white;
                        color: white;
                        margin-bottom: 8px;
                        margin-top: 50px !important;
                    }

                    ul {
                        border: 1px dashed #4D148C;
                        left: -5rem !important;

                        li {
                            padding: 0.5rem;

                            svg {
                                color: #FF6600;
                            }
                        }

                        small {
                            color: #999;
                        }

                        b {
                            color: #666666;
                            font-weight: bold;
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }

                    .dropdown-menu {
                        left: -5rem !important;
                        min-width: 25rem;

                        .row {
                            cursor: pointer;
                        }
                    }

                    select {

                        /* styling */
                        text-align: start;
                        background-color: white;
                        border: thin solid #4D148C;
                        border-radius: 4px;
                        display: inline-block;
                        font: inherit;
                        line-height: 1.5em;
                        padding: 0.5em 3.5em 0.5em 1em;

                        /* reset */

                        margin: 0;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }


                    /* arrows */

                    select.classic {
                        background-image:
                            linear-gradient(45deg, transparent 50%, blue 50%),
                            linear-gradient(135deg, blue 50%, transparent 50%),
                            linear-gradient(to right, skyblue, skyblue);
                        background-position:
                            calc(100% - 20px) calc(1em + 2px),
                            calc(100% - 15px) calc(1em + 2px),
                            100% 0;
                        background-size:
                            5px 5px,
                            5px 5px,
                            2.5em 2.5em;
                        background-repeat: no-repeat;
                        margin-top: 5px;
                    }

                    select.classic:focus {
                        background-image:
                            linear-gradient(45deg, white 50%, transparent 50%),
                            linear-gradient(135deg, transparent 50%, white 50%),
                            linear-gradient(to right, gray, gray);
                        background-position:
                            calc(100% - 15px) 1em,
                            calc(100% - 20px) 1em,
                            100% 0;
                        background-size:
                            5px 5px,
                            5px 5px,
                            2.5em 2.5em;
                        background-repeat: no-repeat;
                        border-color: grey;
                        outline: 0;
                    }




                    select.round {
                        background-image:
                            linear-gradient(45deg, transparent 50%, gray 50%),
                            linear-gradient(135deg, gray 50%, transparent 50%),
                            radial-gradient(#ddd 70%, transparent 72%);
                        background-position:
                            calc(100% - 20px) calc(1em + 2px),
                            calc(100% - 15px) calc(1em + 2px),
                            calc(100% - .5em) .5em;
                        background-size:
                            5px 5px,
                            5px 5px,
                            1.5em 1.5em;
                        background-repeat: no-repeat;
                    }

                    select.round:focus {
                        background-image:
                            linear-gradient(45deg, white 50%, transparent 50%),
                            linear-gradient(135deg, transparent 50%, white 50%),
                            radial-gradient(gray 70%, transparent 72%);
                        background-position:
                            calc(100% - 15px) 1em,
                            calc(100% - 20px) 1em,
                            calc(100% - .5em) .5em;
                        background-size:
                            5px 5px,
                            5px 5px,
                            1.5em 1.5em;
                        background-repeat: no-repeat;
                        border-color: green;
                        outline: 0;
                    }





                    select.minimal {
                        background-image:
                            linear-gradient(45deg, transparent 50%, gray 50%),
                            linear-gradient(135deg, gray 50%, transparent 50%),
                            linear-gradient(to right, #ccc, #ccc);
                        background-position:
                            calc(100% - 20px) calc(1em + 2px),
                            calc(100% - 15px) calc(1em + 2px),
                            calc(100% - 2.5em) 0.5em;
                        background-size:
                            5px 5px,
                            5px 5px,
                            1px 1.5em;
                        background-repeat: no-repeat;
                    }

                    select.minimal:focus {
                        background-image:
                            linear-gradient(45deg, green 50%, transparent 50%),
                            linear-gradient(135deg, transparent 50%, green 50%),
                            linear-gradient(to right, #ccc, #ccc);
                        background-position:
                            calc(100% - 15px) 1em,
                            calc(100% - 20px) 1em,
                            calc(100% - 2.5em) 0.5em;
                        background-size:
                            5px 5px,
                            5px 5px,
                            1px 1.5em;
                        background-repeat: no-repeat;
                        border-color: green;
                        outline: 0;
                    }


                    select:-moz-focusring {
                        color: transparent;
                        text-shadow: 0 0 0 #000;
                    }
                }

            }


            .add-recip-dropdown {
                display: contents;
                max-height: 55px;

                .submit-recipient {
                    background: #4D148C;
                    border-radius: 5px;
                    border: 1px solid white;
                    color: white;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    height: 45px;
                    margin-top: 25px;
                    padding: 0px 20px !important;
                }

                .submit-recipient-2 {
                    // use in template
                    border-radius: 5px !important;
                }

                .dropdown-toggle {
                    background: #4D148C;
                    border-radius: 5px;
                    border: 1px solid white;
                    color: white;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    height: 45px;
                    margin-bottom: 8px;
                }

                .dropdown-toggle::after {
                    margin-left: 0.200em;
                    font-size: 20px;
                    margin-right: 0.300rem;
                    margin-top: 0.800rem;
                }

                ul {
                    border: 1px dashed #4D148C;
                    left: -5rem !important;

                    li {
                        padding-left: 2rem;
                    }

                    b {
                        color: #666666;
                        font-weight: bold;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }

                .dropdown-menu {
                    left: -5rem !important;
                    min-width: 15rem;
                    margin-top: 0.5rem;
                }
            }
        }
    }

    // envelop add recipient modal (add private message)
    .add_recipient_modal {
        .private-col {
            border: 0px;
            border-bottom: 5px solid #FF6600;

            span {
                color: #eee;
                padding-left: 20px;
            }
        }



        .time-icn-close-modal {
            background: #FF6600;
            border: 2px solid #4D148C;
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            float: right;
            right: -20px;
            top: -20px;

            svg {
                margin-top: 2px;
                width: 0.8rem;
                color: #E3F8FA;
                height: 2rem;
                margin-left: -2px;
            }
        }

        .private-col-bottom {
            background: #4D148C;
            padding-top: 15px;
            padding-bottom: 15px;
            border: 0px;
            border-top: 5px solid #FF6600;
            border-radius: 0px 0px 10px 10px;
            text-align: right;
        }

        .comment-section {
            padding-bottom: 30px;

            .textarea-field {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .done-btn {
            padding-top: 7px;
            padding-bottom: 7px;
            border-radius: 5px;
            margin-left: 5px;
            margin-right: 10px;
        }

        .done-btn:hover {
            background: #666666;
            color: white;
            border: 1px solid white;
        }

        .cancel-btn {
            padding-top: 7px;
            padding-bottom: 7px;
            background: transparent;
            color: white;
            border-radius: 5px;
            border: 0px;
            margin-right: 5px;
            margin-left: auto;
        }

        .cancel-btn:hover {
            background: #eee;
            font-weight: bold;
            color: #4D148C;
            border: 1px solid #4D148C;
        }
    }

    .add_a_contact_modal {
        .side-bar {
            .side-bar-btn {
                border-radius: 4px;
                font-weight: 500;
                font-size: 16px;
                margin: 10px 5px;
                padding: 5px;
            }

            svg {
                width: 1rem;
                height: 1rem;
                font-weight: bold;
                color: #000000;
            }

            .side-bar-active-btn {
                background: rgba(255, 102, 0, 0.5);
                border: 2px solid #FF6600;

                button {
                    color: #4D148C;
                }

                svg {
                    color: #4D148C;
                }
            }

            button {
                background: transparent;
                border: 0px;
                color: #000000;
                font-weight: bold;
            }

            .add-1 {}

            .dirct-1 {}

        }

        table {
            width: -webkit-fill-available !important;

            .sucs {
                background: white !important;
                font-weight: bold;
                color: black !important;
            }

            td {
                padding: 1px 5px !important;
            }
        }

        .select-recpt {
            color: red;
            background: white;
            align-self: center;
            padding: 5px;
            border-radius: 5px;
            margin-left: 5px;
            font-weight: bold;
            text-align: center;
        }
    }

    .add_signature_modal {
        padding: 0px 12px;
    }

    // envelop prepare document
    .recipt-contanier-fluid {
        padding-top: 100px;
        background: #E3F8FA;
        border-radius: 0px 0px 15px 15px;
        // padding-bottom: 70px;
        // margin-bottom: 30px;
    }

    // envelop review document
    .env_reviw_doc {
        padding: 20px 0px !important;

        .adc-2 .lb-4 {
            font-size: 16px;
            color: #4D148C;
        }

        .adc-2 .dl-2 {
            color: #FF6600;
            margin-left: auto;
            padding-right: 5px;
            margin: 0px;
            align-self: center;

            label {
                margin: 0px;
            }

            p {
                margin-left: auto;
                margin-bottom: 0px !important;
            }
        }
    }

    .review_show_recipient_modal {
        .card-header {
            width: 100% !important;
            text-align: center;
        }

        ul li .col {
            align-self: center;
        }

        ul li .col-md-2 {
            margin: 2px 0px;
        }

        ul li span {
            padding: 8px 10px;
            background: #eee;
            border-radius: 50%;
            color: black;
            font-size: 26px;
        }
    }

    .tiny-footer {
        margin: 0px -1rem;
        align-self: center;

        .tiny-foot-section {
            margin-top: 10px;
        }

        .float-right {
            margin-right: 30px;
        }

        .back-btn {
            border-radius: 10px;
            padding: 5px 40px;
            background-color: transparent;
            color: #666;
            border: none;

            svg {
                padding-top: 3px;
            }
        }

        .back-btn:hover {
            background-color: #4D148C;
            color: white;
        }

        .tiny-footer-btn {
            border-radius: 10px;
            padding: 5px 40px;
            margin-left: 40px;
            margin-right: 34px;
            margin-top: 5px;

            svg {
                padding-top: 3px;
            }
        }

        .tiny-footer-btn:hover {
            background-color: #4D148C;
        }
    }

    .tiny-footer-1 {
        height: 60px;
    }

}



.profile {
    text-align: center;
}

.profile img {
    background: white;
    padding: 5px;
    max-width: 160px;
    width: 100%;
    margin: 0 auto;
    -webkit-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    -o-transform: translate3d(0, -50%, 0);
    -ms-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
}

.profile .avatar {
    height: 90px;
}

.img-raised {
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, .24), 0 8px 10px -5px rgba(0, 0, 0, .2);
}

.rounded-circle {
    border-radius: 50% !important;
}

.img-fluid,
.img-thumbnail {
    max-width: 100%;
    height: auto;
}

// home   date 6th dec
// .hom-1 {
//     .card-header {
//         font-weight: bold;
//         border-top: 1px solid #d8dbe0
//     }

//     .brt-0 {
//         border-top: 0px
//     }

//     .tb-1 {
//         margin: 0px 3%;

//         th {
//             width: 20%;
//             padding: 0.2rem 0.5rem !important;
//             border: 0px !important;
//         }

//         td {
//             width: 30%;
//             padding: 0.2rem 0.5rem !important;
//             border: 0px !important;
//         }
//     }

//     .tb-2 {
//         margin: 0px 3%;

//         th {
//             width: 20%;
//         }

//         td {
//             width: 30%;
//         }
//     }

//     .blnk-1 {
//         justify-content: center;
//         text-align: center;
//         width: 100%;

//         a {
//             color: #666666 !important;
//             font-weight: bold;
//         }
//     }

//     .prrw-2 {
//         .sig-2 {
//             align-self: center;
//             margin: 4% 0px 4% 15%;
//         }

//         .lgo-3 {
//             border: 2px solid;
//             border-radius: 10px;
//             height: 130px;
//             margin: 0px 1%;
//             background-color: white;
//         }

//         .st-1 {
//             margin: 1% 0px 1% 10%;
//             align-self: center;
//         }

//         .bd-1 {
//             height: 120px;
//         }

//         .bd-2 {
//             height: 153px;
//         }
//     }

//     .trfc-1 {
//         display: block;
//     }


//     // contact and notification
//     .contif-1 {

//         // home contact
//         .cont-1 {
//             .tb-3 {
//                 margin: 3%;

//                 thead {
//                     background-color: #ebedef;
//                     border-radius: 5px;
//                     border-bottom: 1px solid #d8dbe0;
//                 }

//                 th {
//                     padding-left: 5px;
//                 }
//             }

//             .card {
//                 font-weight: bold;
//             }

//             .card-header {
//                 font-weight: bold;
//                 background-color: #4D148C;
//                 color: white;
//             }

//             .card-body {
//                 justify-content: center !important;
//                 text-align: center;
//                 padding-bottom: 1.25rem !important;

//                 .cont-2 a {
//                     text-decoration: none;
//                 }

//                 img {
//                     height: 100px;
//                     padding: 1px;
//                     background-color: #4D148C;
//                     border: 2px solid;
//                     border-radius: 50%;
//                 }

//                 p {
//                     color: #333;
//                     margin: 0px;
//                 }

//                 small {
//                     color: #999;
//                 }

//                 .blnk-1 a {
//                     text-decoration: none;
//                 }

//                 .blnk-1 a:hover {
//                     // text-decoration: underline;
//                 }
//             }

//             .card-footer {
//                 font-weight: bold;
//                 text-align: right;
//                 color: #666666;
//                 cursor: pointer;

//                 a {
//                     color: #4D148C;
//                     text-decoration: none;
//                 }

//                 a:hover {
//                     color: #FF6600;
//                     // text-decoration: underline;
//                 }
//             }

//             .cont-2 {
//                 .card {
//                     margin: 0px;
//                 }
//             }
//         }

//         // home notification
//         .noti-1 {
//             .blnk-1 {
//                 padding: 1.25rem;

//                 p {
//                     margin: 0px;
//                 }
//             }

//             .card {
//                 font-weight: bold;
//             }

//             .card-header {
//                 font-weight: bold;
//                 background-color: #FF6600;
//                 color: white;
//             }

//             .card-body {
//                 padding-top: 0px;
//                 padding-bottom: 0px;

//                 .cont-2 {
//                     .c-avatar .c-avatar-img {
//                         border: 1px solid #4D148C;
//                     }

//                     .c-avatar .c-avatar-status {
//                         top: 35px;
//                         right: -2px
//                     }

//                     .dropdown-item .col-10 {
//                         line-height: 1.3,
//                     }

//                     .border-bottom {
//                         border-bottom: 1px solid #4D148C !important;
//                         margin-left: 25% !important;
//                         margin-right: 25% !important;
//                         padding: 2px;
//                     }
//                 }

//                 .blnk-1 a {
//                     text-decoration: none;
//                 }

//                 .blnk-1 a:hover {
//                     // text-decoration: underline;
//                 }
//             }

//             .card-footer {
//                 font-weight: bold;
//                 text-align: right;
//                 color: #666666;
//                 cursor: pointer;

//                 a {
//                     color: #4D148C;
//                     text-decoration: none;
//                 }

//                 a:hover {
//                     color: #FF6600;
//                     // text-decoration: underline;
//                 }
//             }
//         }
//     }

//     // manage and tempalte
//     .temng-1 {
//         .tpl-1 {
//             .tb-3 {
//                 margin: 3% 0px;

//                 thead {
//                     background-color: #EAEAEA;
//                     border-radius: 5px;
//                     border-bottom: 1px solid #d8dbe0;
//                     padding: 0px 2px;
//                 }

//                 th {
//                     padding-left: 5px;
//                 }
//             }

//             .card-header {
//                 font-weight: bold;
//                 background-color: #FF6600;
//                 color: white;
//             }

//             .card-body {
//                 padding-top: 0px;
//                 padding-bottom: 0px;

//                 .blnk-1 a {
//                     text-decoration: none;
//                 }

//                 .blnk-1 a:hover {
//                     // text-decoration: underline;
//                 }
//             }

//             .card-footer {
//                 font-weight: bold;
//                 text-align: right;
//                 color: #666666;
//                 cursor: pointer;

//                 a {
//                     color: #4D148C;
//                     text-decoration: none;
//                 }

//                 a:hover {
//                     color: #FF6600;
//                     // text-decoration: underline;
//                 }
//             }
//         }

//         .mnag-1 {
//             .tb-3 {
//                 margin: 3% 0px;

//                 thead {
//                     background-color: #EAEAEA;
//                     border-radius: 5px;
//                     border-bottom: 1px solid #d8dbe0;
//                 }

//                 th {
//                     padding-left: 5px;
//                 }
//             }

//             .card-header {
//                 font-weight: bold;
//                 background-color: #4D148C;
//                 color: white;
//             }

//             .card-body {
//                 padding-top: 0px;
//                 padding-bottom: 0px;

//                 .blnk-1 a {
//                     text-decoration: none;
//                 }

//                 .blnk-1 a:hover {
//                     // text-decoration: underline;
//                 }
//             }

//             .card-footer {
//                 font-weight: bold;
//                 text-align: right;
//                 color: #666666;
//                 cursor: pointer;

//                 a {
//                     color: #4D148C;
//                     text-decoration: none;
//                 }

//                 a:hover {
//                     color: #FF6600;
//                     // text-decoration: underline;
//                 }
//             }
//         }
//     }

//     .dsh-2 {
//         margin-bottom: 30px;
//         padding: 20px 0px 0px 20px;
//         border-radius: 15px;
//     }
// }

.mng-1 {
    // margin-top: -2rem;
    // padding: 0px 5rem;
    padding: 0rem 2rem 0rem 6rem;
}

.mng-1 .tab-content {
    padding: 0px;
}

.mng-1 .hd-2 {
    // min-height: 72.7vh;
}

.mng-1 .hd-2 .c-sidebar {
    position: inherit;
    background: #4D148C;
}

.mng-1 .hd-2 .c-sidebar .st-1 {
    padding: 0.5rem;
}

.mng-1 .hd-2 .c-sidebar .txt-1 {
    margin: 0px;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
    border-bottom: 1px solid;
}

.mng-1 .hd-2 .c-sidebar ul li a {
    padding-left: 1.5rem;
    font-size: 18px;
}

.mng-1 .hd-2 .c-sidebar .btn svg {
    margin-bottom: 0.4rem;
}

.mng-1 .hd-2 .c-sidebar .nav-link svg {
    color: #ebedef !important;
    margin-right: 0.5rem;
}

// .mng-1 .tab-content {width: 85%;}

//manage inbox tab
.mng-1 .inbx-1 .card-header {
    padding: 1.28rem;
}

.mng-1 .inbx-1 .card-title {
    margin: 0.2rem;
    font-weight: bold;
    font-size: 1.5rem;
}

.mng-1 .inbx-1 .emh-1 {
    align-self: center;
    color: #4D148C;
    margin: 2rem;
}

.mng-1 .inbx-1 .emh-2 .hed-1 {
    background: #4D148C;
    color: white;
}

.mng-1 .inbx-1 .emh-2 .hed-1 .col {
    align-self: center;
}

.mng-1 .inbx-1 .emh-2 .hed-1 .form-check-input {
    outline: -webkit-focus-ring-color auto 1px;
    margin-left: -0.4rem !important;
    border: 1px solid white;
    height: 20px;
    width: 20px;
}

.mng-1 .inbx-1 .emh-2 .hed-1 .checkbox {
    padding-left: 1rem;
}

.mng-1 .inbx-1 .emh-2 .form-check-input {
    margin: auto;
}

.mng-1 .inbx-1 .emh-2 .checkbox {
    padding-top: 5px;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col5 {
    text-transform: capitalize;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col5 h5 {
    cursor: pointer;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col5 h5:hover {
    -webkit-text-stroke: 1px #4D148C;
    -webkit-text-fill-color: white;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 p {
    color: #999;
    font-size: 15px;
    margin: 0px;
    text-transform: capitalize;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .prog-1 {
    cursor: pointer;
    height: 12px;

    .progress-bar {
        font-size: 10px;
    }
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .ml-a {
    margin-left: auto !important;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .mr-a {
    margin-right: auto !important;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col-1 {
    color: #999;
    font-size: 15px;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col2 {
    display: flex;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col2 svg {
    margin: 0px 5px;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col3 p b {
    color: #999;
    font-size: 16px;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col3 p {
    color: #999;
    font-size: 16px;
    font-weight: 500;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col9 {
    align-self: center;
    padding: 0px 5px;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col-3 {
    display: flex;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col {
    padding: 10px;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .col .col-3 {
    padding: 0px !important;
}

.mng-1 .inbx-1 .emh-2 .bdy-1 .pr-1 {
    display: block
}

.mng-1 .inbx-1 .card-title {
    margin: 0.2rem;
    font-weight: bold;
    font-size: 1.5rem;
}


//manage send tab
.mng-1 .snt-1 .card-header {
    padding: 1.28rem;
}

.mng-1 .snt-1 .card-title {
    margin: 0.2rem;
    font-weight: bold;
    font-size: 1.5rem;
}

.mng-1 .snt-1 .emh-1 {
    align-self: center;
    color: #4D148C;
    margin: 2rem;
}

.mng-1 .snt-1 .emh-2 .hed-1 {
    background: #4D148C;
    color: white;
}

.mng-1 .snt-1 .emh-2 .hed-1 .col {
    align-self: center;
}

.mng-1 .snt-1 .emh-2 .hed-1 .form-check-input {
    outline: -webkit-focus-ring-color auto 1px;
    margin-left: -0.4rem !important;
    border: 1px solid white;
    height: 20px;
    width: 20px;
}

.mng-1 .snt-1 .emh-2 .hed-1 .checkbox {
    padding-left: 1rem;
}

.mng-1 .snt-1 .emh-2 .form-check-input {
    margin: auto;
}

.mng-1 .snt-1 .emh-2 .checkbox {
    padding-top: 5px;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col5 {
    text-transform: capitalize;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col5 h5 {
    cursor: pointer;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col5 h5:hover {
    -webkit-text-stroke: 1px #4D148C;
    -webkit-text-fill-color: white;
}

.mng-1 .snt-1 .emh-2 .bdy-1 p {
    color: #999;
    font-size: 15px;
    margin: 0px;
    text-transform: capitalize;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .prog-1 {
    cursor: pointer;
    height: 12px;

    .progress-bar {
        font-size: 10px;
    }
}

.mng-1 .snt-1 .emh-2 .bdy-1 .ml-a {
    margin-left: auto !important;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .mr-a {
    margin-right: auto !important;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col-1 {
    color: #999;
    font-size: 15px;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col2 {
    display: flex;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col2 svg {
    margin: 0px 5px;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col3 p b {
    color: #999;
    font-size: 16px;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col3 p {
    color: #999;
    font-size: 16px;
    font-weight: 500;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col9 {
    align-self: center;
    padding: 0px 5px;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col-3 {
    display: flex;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col {
    padding: 10px;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .col .col-3 {
    padding: 0px !important;
}

.mng-1 .snt-1 .emh-2 .bdy-1 .pr-1 {
    display: block
}

.mng-1 .snt-1 .card-title {
    margin: 0.2rem;
    font-weight: bold;
    font-size: 1.5rem;
}





// document detail

.docD-1 .card-header {
    font-size: 1rem !important;
    background-color: white !important;
    svg {
        border: 1px solid;
        color: #4D148C;
        border-radius: 50%;
        cursor: pointer;
        margin-left: 0.3rem;
        margin-bottom: 0.3rem;
        height: 38px;
    }

    p b {
        color: #999;
        font-size: 16px;
    }

    p {
        color: #999;
        font-size: 16px;
        font-weight: 500;
        margin: 0px;
    }

    .card-title ul {
        padding: 0px;
        margin: 0px;
    }

    .card-title li {
        list-style: none;

        .dropdown-item.active,
        .dropdown-item:active {
            background-color: #4D148C;
            color: white !important;

            b {
                color: white !important;
            }
        }
    }

    .card-title li .nav-link {
        padding: 1px 5px;
    }

    .card-title {
        display: flex;
        text-transform: capitalize;
        font-weight: bold;
        margin: 0.5rem 0px;
    }

    .card-subtitle a {
        color: #FF6600;
    }

    .card-subtitle a:hover {
        -webkit-text-stroke: 1px #4D148C;
        -webkit-text-fill-color: white;
        text-decoration: none;
    }

    .cl-1 {
        display: flex;

        .p-2 {
            margin-left: auto;
            float: right;
        }
    }

    .wt-1 {
        margin: 0.5rem 100px 0.5rem;
    }

    .wt-1 svg {
        margin-right: 0.2rem;
        margin-top: 0.1rem;
        color: #4D148C;
    }

    .rw-1 {
        display: flex;
        // margin-bottom: 1rem !important;
    }

    .rw-1 .btn {
        text-transform: uppercase;
        font-weight: bold;
        background: #4D148C;
        cursor: not-allowed;
        // height: 38px;
        color: white;
        // width: 10%;
    }

    .rw-1 .btn-k {
        cursor: pointer;
    }

    .rw-1 .bt-1 {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-left: 110px;
        // padding: 0.5rem 1rem;
        // height: 40px;
    }

    .rw-1 .bt-2 {
        margin-left: auto;
        width: 4%;
        cursor: not-allowed;

        svg {
            margin: 0px;
            color: white;
            border: 0px;
            border-radius: 0px;
            cursor: not-allowed;
        }
    }

    .rw-1 .bt-3 {
        width: 4%;
        cursor: not-allowed;

        svg {
            margin: 0px;
            color: white;
            border: 0px;
            border-radius: 0px;
            cursor: not-allowed;
        }
    }

    .rw-1 .bt-4 {
        width: auto;
        margin-left: auto;
        // padding-top: 0.5rem;

        svg {
            // margin-bottom: 0.5rem;
            // margin-left: 0.5rem;
            // color: white;
            // border: 0px;
            // border-radius: 0px;
        }
    }

    .rw-1 ul {
        padding: 0px;
        margin: 0px;
    }

    .rw-1 .nav-item {
        display: table;
        // height: 50px;
        list-style: none;
        border-left: 1px solid white;
        // padding: 3px 0px;
        background: #4D148C;
        color: white;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    .rw-1 .nav-item svg {
        color: white;
        border: 0px;
        margin: 0px;
        border-radius: 0px;
    }

    .rw-1 .nav-item .dropdown-menu a {
        color: #4D148C;
        cursor: not-allowed;
    }

    .rw-1 .nav-item .dropdown-menu svg {
        cursor: not-allowed;
        border-radius: 0px;
        border: 0px;
        margin-right: 0.5rem;
        margin-bottom: 0.2rem;
        margin-left: -0.3rem;
        color: #4D148C;
    }
}

.docD-1 .card-body {
    .col {
        padding: 0px;
    }

    .card-header {
        display: flex;
    }

    .card-header h5 {
        align-self: center;
        margin-left: auto;
        cursor: not-allowed;

        svg {
            color: #4D148C;
            border: 0px;
            border-radius: 0px;
            cursor: not-allowed;
            margin-right: 0.3rem;
        }
    }

    .card-body {
        .hed-2 {
            background: #4D148C;
            color: white;
            font-weight: bold;
            padding: 0.5rem 0.5rem;

            .col-1 {
                text-align: center;
                align-self: center;
            }
        }

        .bdy-2 {
            // padding: 1rem;

            .col-2 {
                .ac-1 {
                    margin-left: auto;
                    align-self: center;
                    padding: 0.2rem;
                }

                .ac-2 {
                    align-self: center;
                    padding: 0.2rem;

                    svg {
                        margin-bottom: 0.3rem;
                        color: #39f;
                    }
                }

                .ac-3 {
                    margin-right: auto;
                    align-self: center;
                    padding: 0.2rem;
                }
            }

            .col-1 {
                text-align: center;
                align-self: center;
            }

            .col i {
                text-transform: uppercase;
            }

            .col i:hover {
                font-weight: bold;
            }

            .col p {
                color: #666;
                margin: 0px;
            }

            .col-3 p {
                color: #666;
            }

            .col-3 b {
                color: #4D148C;
            }

            ul {
                list-style: none;
                margin: 0px;
                padding: 0px;
                align-self: center;
            }

            ul .nav-link {
                padding: 0px;
                margin-bottom: 0rem;
                margin-left: 0.1rem;
            }

            ul li .dropdown-menu {
                padding: 0px;
            }

            svg {
                color: #666;
            }
        }
    }
}


.docD-1 .card-footer {
    h4 {
        padding-top: 0.5rem;
        font-weight: bold;
    }

    .card-body {
        padding: 0.5rem 1rem;
    }

}

.docC-1 {
    .head-logo {
        margin: 1rem 3rem;
        height: 60px;
    }

    table {
        width: 100%;
    }

    #print_certificate {
        border: 0px;

        .card-title {
            width: -webkit-fill-available;
        }

        .card-header {
            border: 2px solid black;
            background-color: darkgrey;
            color: black;
        }
    }
}

.demoMrg-1 {
    margin-top: 1.6rem;
}


// document review 

.DocR-1 {
    border: 0px;
    display: -webkit-box !important;
    margin-bottom: 0px;

    .col-md-3 {
        padding: 0px !important;
        height: 820px;
        background-color: #4D148C;

        // .hed-1{background: #321fdb; border: 0px; border-radius: 0px;}
        .card-header {
            background-color: #4D148C;
            color: white;
            border: 0px;
            border-radius: 0px;

            svg {
                border: 1px solid;
                color: white;
                border-radius: 50%;
                cursor: pointer;
                margin-left: 0.3rem;
                margin-bottom: 0.3rem;
            }

            .card-title {
                color: white;
            }

            .card-title ul {
                padding: 0px;
                margin: 0px;
            }

            .card-title li {
                list-style: none;
            }

            .card-title li {
                list-style: none;

                .dropdown-item.active,
                .dropdown-item:active {
                    background-color: #4D148C;
                    color: white !important;

                    b {
                        color: white !important;
                    }
                }
            }

            .card-title li .nav-link {
                padding: 1px 5px;
            }

            .card-title {
                display: flex;
                text-transform: capitalize;
                font-weight: bold;
                margin: 0.5rem 0px;
            }

            .card-subtitle a {
                color: #eee;
            }

            .card-subtitle a:hover {
                -webkit-text-stroke: 1px white;
                -webkit-text-fill-color: #666;
                text-decoration: none;
            }
        }

        .card-body {
            background: white;
            border-left: 5px solid #4D148C;
            border-right: 5px solid #4D148C;
            padding: 0.5rem;

            .sndi-2 h4 {
                display: block;
                color: #4D148C;
                font-weight: bold;
                text-align: end;
                border-right: 3px solid #4D148C;
                margin-top: 5px;
            }

            .sndi-2 .snd-1 {
                font-weight: bold;
                color: #4D148C;
                font-size: 18px;
                text-transform: uppercase;
            }

            .sndi-2 .snd-1 h5 {
                color: #FF6600;
                text-transform: uppercase;
                margin-bottom: 3px;
            }

            .sndi-2 .snd-1 h6 {
                color: #999;
                text-transform: lowercase;
                font-size: 16px;
            }

            .sec-1 {
                margin: 0.2rem 0.5rem;
                padding: 0.2rem 1.5rem;
                background: green;
                color: white;
                border: 1px solid lightgray;
                border-radius: 5px;

                h5 {
                    margin-bottom: 2px;
                    text-align: center;
                }

                h6 {
                    margin-bottom: 2px;
                    text-transform: uppercase;
                    color: white;
                    padding-top: 3px;
                    padding-left: 0px
                }
            }

            .dag-1 {
                margin: 0.2rem 0.5rem;
                padding: 0.2rem 1.5rem;
                background: red;
                color: white;
                border: 1px solid lightgray;
                border-radius: 5px;

                h5 {
                    margin-bottom: 2px;
                    text-align: center;
                }

                h6 {
                    margin-bottom: 2px;
                    text-transform: uppercase;
                    color: white;
                    padding-top: 3px;
                    padding-left: 0px
                }
            }
        }

        .card-footer {
            background: #4D148C;
            color: white;
            border: 0px;
            ;

            h5 {
                color: white;
                padding: 0.5rem 1rem;
                font-weight: bold;
            }

            .list-group-item {
                background: #ebedef;
                color: #4D148C;

                .col-md-11 {
                    color: #666;

                    p {
                        font-size: 15px;
                        margin-bottom: 0px;
                    }
                }

                p {
                    margin: 0px;
                    color: #666;
                }

                b {
                    color: black !important;
                }

            }
        }

        // .card-header .sndi-2 h4{}
        // .card-header .sndi-2 .snd-1{font-weight: bold;color: white;font-size: 18px; text-transform: uppercase;}
        // .card-header .sndi-2 .snd-1 h5{ color: #eeeeee; text-transform: uppercase; margin-bottom: 3px;}
        // .card-header .sndi-2 .snd-1 h6{ color: lightgray; text-transform: lowercase; font-size: 15px;}
        // .card-header .sndi-2 small{font-size: 12px; color: lightgray;}
        .bg-gradient-secondary {
            margin: 0px;
            border: 0px !important;
        }

        .bg-gradient-secondary .card-header {
            font-size: 20px;
            font-weight: bold;
            color: #321fdb;
        }

        .bg-gradient-secondary .lb-4 {
            text-transform: uppercase;
            padding: 0.1rem 0.5rem;
            font-weight: bold;
        }

        .bg-gradient-secondary .form-control:disabled,
        .form-control[readonly] {
            background: white;
        }

        .crd-2 {
            border: 0px;
            border-radius: 0px;
            background: #321fdb;
            margin: 0px;
        }

        .crd-2 .card-body {
            padding: 0.5rem 1.25rem
        }

        .crd-2 .card {
            margin: 0.5rem;
        }

        .crd-2 .card-body .p-0:hover {
            text-decoration: none;
            font-weight: bold;
        }

        .crd-2 .card-body .form-control:disabled,
        .form-control[readonly] {
            background: white;
        }

        hr {
            margin: 0px;
            border: 2px solid white;
        }

        .crd-2 .card-body .ls-1 .list-group-item {
            padding: 0.5rem 0.8rem;

            .col-md-11 {
                color: #666;

                p {
                    font-size: 15px;
                    margin-bottom: 0px;
                }
            }
        }

        .crd-2 .card-body .ls-1 .list-group-item .col {
            padding: 0px 10px;
        }

        .fot-1 {
            background: #321fdb;
            border: 0px;
            border-radius: 0px;
            cursor: pointer;
        }

        .fot-1 .sts-1 h4 {
            margin: 0px auto;
            font-weight: bold;
        }
    }

    .opcty {
        opacity: 0.5;
    }

    .col-md-9 {
        padding: 0px !important;

        #viewDoc {
            height: 82.6vh;
        }

        .viewer-annotation-layer .sig-2 .sig-container {
            position: inherit;
        }
    }
}







.mb-3,
.my-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 2px !important;
}

.mb-4,
.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 2px !important;
}

.r-1 small {
    padding: 0.5rem 1rem 1rem;
    float: right;
}

.log-1 small {
    padding: 0.5rem 1rem 1rem;
    float: right;
}

.log-1 .card-body {
    padding: 7% !important;
}

.c-avatar-z-logo {
    // padding: 10px;         //commited on 22nd nov
    // margin-bottom: 2%;
    padding: 5px;          
    // margin-bottom: -3px;
     width: 20%;   //added 22 nov
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 8px;
}

.log-1 .py-5 h1 {
    padding-top: 1rem;
}

.log-1 .recaptcha {
    text-align: -webkit-center;
}

.log-1 .ch-pass-inpt {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.log-1 .ch-pass-icn {
    float: right;
    margin-left: -30px !important;
    margin-top: 7px;
    position: relative;
    z-index: 2;

    i {
        padding-right: 10px !important;
    }

    .fa-eye {
        color: #FF6600;
        width: 1.2rem;
    }

    .fa-eye-slash {
        color: #666;
        width: 1.2rem;
    }
}


.c-main {
    // padding-top: 1.5rem;  on 21st of nov 2022
    padding-top: 0rem
}

.c-main .alert {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    padding: 0;
    list-style: none;
    margin-top: -1rem;
    background: transparent;
    border: none;
}

.reg .py-5 {
    padding: 1rem !important;
}

.reg-1 small {
    padding: 0.5rem 1rem 1rem;
    float: right;
}

.reg-1 .try-1 {
    margin-right: 60%;
}

.reg-1 .mt-3:hover {
    border: none;
}

.reg .reg-2 {
    .lf-1 .input-group-text {
        width: 6rem;
        color: #4D148C;
        background: none;
        border: 0px;
        font-weight: bold;
    }
}

.reg-1 .recaptcha {
    text-align: -webkit-center;
}

.eml-1 h1 {
    padding-top: 2rem;
}

.eml-1 .c-avatar-img {
    width: 60%;
    border-radius: 49%;
    float: right;
}

.eml-1 .sub-2 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-left: 0px;
}

.c-main .alert p {
    padding: 1rem 4rem;
    margin: 0px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
}

.ca-app .card-body {
    padding: 2rem 5rem !important;
}

.eml-1 .resendCode {
    cursor: pointer;
}

.ca-app h1 {
    padding: 1rem !important;
}

.mt-3:hover {
    border: 1px solid white;
}

input[type="date"]::before {
    color: #999999;
    content: "demo";
}

input[type="date"] {
    color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
    color: #666666;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
    content: "" !important;
}

.pad-3 {
    margin-top: 15%;
}

.pad-3 .pg-1 {
    color: gray;
}

.pad-5 {
    margin-top: 25%;
}

.pad-5 p {
    margin: 0px;
}

.pad-5 a {
    color: #321fdb !important;
    font-weight: bold;
}


.pad-2 {
    margin-top: 10%;
}

.nv-1 {
    border: 1px solid #c4c9d0;
    margin-bottom: 1rem;
    border-radius: 5px;
}

.nv-1 .nav-item {
    margin: 0px;
}

.nv-1 .nav-link {
    border: none;
}

.nv-1 .nav-link.active {
    color: #FF6600 !important;
    font-weight: bold;
    border: none;
    height: 100%;
}

.nv-1 .mn-2 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: black;
}

.nv-1 .mn-2:hover {
    color: black;
}

.nv-1 .ye-2 {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    color: black;
}

.nv-1 .ye-2:hover {
    color: black;
}

.ca-k {
    background: #321fdb;
}

.ca-app {
    color: #4D148C;
}

.ca-app .card {
    border: none;
}

.ca-app .cl-1 {
    padding-top: 8rem !important;
}

.ca-app .cp-2 {
    color: #ebedef !important;
    font-weight: bold;
}

.ca-app a {
    color: #4D148C;
    font-weight: bold;
    font-size: 16px;
}

.cl-1 a {
    color: #4D148C;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

.ca-app .modal {
    color: gray;
}

.l-4 {
    color: lightgray !important;
}

.text-white .l-4 {
    color: lightgray !important;
}

.pln-2 {
    background: #321fdb;
    padding: 2%;
    border-radius: 10px;
    margin: 1rem;
}

.pln-2:hover {
    background-color: #2a1ab9;
    border-color: #2819ae;
}

.pln-2 .pln-2a {
    color: white !important;
    font-size: 16px;
}

.pln-2 .pln-2a:hover {
    color: lightgray;
}

.jb-1 {
    padding: 1rem;
    background-color: white;
    border-radius: 0px;
    margin: 0px;
}

.jb-1 h1 {
    font-size: 3rem;
    font-weight: bold;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    margin: 0px;
}

.jb-1 small {
    font-size: 1.5rem;
    text-shadow: -0px -0px 0 #000, 0px -0px 0 #000, -0px 0px 0 #000, 0px 0px 0 #000;
}

.mta-3 {
    padding: 1rem;
    font-size: 1rem;
    margin: 0px;
}

.mtb-3 {
    margin-top: 0.7rem;
}

.mtb-3:hover {
    border: none;
    cursor: pointer;
}

.mtd-3:hover {
    border: 2px solid red;
}

.mti-3:hover {
    border: 2px solid #39f;
}

.mtw-3:hover {
    border: 2px solid #f9b115;
}

.mts-3:hover {
    border: 2px solid green;
}

.mtv-3 {
    padding: 5% 15%;
}

.c-app .cba-1 {
    padding: 1rem !important;
}

.c-app .cb-1 {
    padding: 1rem !important;
    height: 600px;
}

.c-app .cb-1 .list-group-item {
    border: none;
    padding: 0.3rem 0px;
    border-bottom: 1px solid #c4c9d0;
    text-align: left;
}

.brd-1 {
    border-block-end: 2px solid #c4c9d0;
    // padding: 0.5rem;
}

#headingOne .p-0:hover {
    text-decoration: none;
    font-weight: bold;
}

#headingOne .p-0:focus {
    text-decoration: none;
    box-shadow: none;
}

#accordion .card-body {
    font-size: 16px;
    line-height: 1.5rem;
}

.modal-content .modal-title {
    font-size: 24px;
    font-weight: bold;
    padding-left: 1rem;
}

.modal-content .modal-header .close {
    color: white;
    opacity: 1;
}

.modal-content .modal-header .close:focus {
    outline: 0px;
}

.modal-content .modal-body {
    font-size: 18px;
    text-align: center;
    color: #4D148C;
}

// .psd-1 .card-body{color: white; padding:0px;}
.psd-1 .text-muted {
    color: #ebedef !important;
}

.psd-1 .pd-5 {
    padding: 0.5rem;
    border: none;
}

.ca-app .pd-5 {
    padding: 0px;
}

.psd-1 .sub-2 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.psd-1 .sub-2:hover {
    color: lightgray;
}

.psd-1 .sub-2:focus {
    color: #4D148C;
    outline: 1px auto -webkit-focus-ring-color;
}

.psd-1 {
    background-color: white;
    color: black
}

.psd-1 .cfrm-1 {
    margin-left: auto;
    margin-right: auto;
}

.psd-1 .cfrm-1 .input-group-text {
    border: none;
    background-color: white;
    font-weight: bold;
    color: #000;
    font-size: 20px;
    padding-top: 0px;
    padding-right: 5rem;
}

//profile layout
.pro-1 {
    margin-top: -2rem !important;
    margin-left: -30px;
    margin-right: -30px;
}

.pro-3 {
    margin-bottom: 1rem;
}

.fir-1 .profile img {
    border: 3px solid #FF6600;
}

.pro-3 .c-sidebar {
    position: absolute;
    width: 100%;
    background: #4D148C;

}

.c-header-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 21, 0.5)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.pro-3 .c-sidebar-nav-item a:active,
a:hover,
a:focus {
    outline: none !important;
}

.pro-3 .tog-1 {
    float: left;
    padding-top: -1rem;
}

.pro-3 .toi-0 {
    position: absolute;
}

.pro-3 .c-sidebar-nav-item {
    padding: 1rem 2rem
}

.pro-3 .c-sidebar-nav-item a {
    color: white;
    cursor: pointer;
}

.pro-3 .c-sidebar-nav-item a:active,
a:hover,
a:focus {
    // font-weight: bold;
    text-decoration: none;
    outline: -webkit-focus-ring-color auto 1px;
}

.pro-3 .c-sidebar {
    border-radius: 10px;
}

.pro-3 .fir-1 {
    padding-top: 1rem;
}

.pro-3 .snd-1 {
    padding-left: 15px;
}

.pro-3 .col-md-3 {
    margin-bottom: 1.5rem;
    padding-left: 0px;
}

.pro-3 .sub-title {
    padding-bottom: 1rem;
}

.pro-3 .pic-1 .card {
    align-items: center;
}

.pro-3 .pic-1 img {
    height: 200px;
    width: 200px;
}

.pro-3 .upl-1 .selectPicture {
    max-width: 100%;
    max-height: 200px;
    border-radius: 5px;
}

.pro-3 .upl-1 .profileUpload {
    height: 200px;
    width: 200px;
}

.pro-3 .upl-1 .card {
    align-items: center;
}


// profile 
.per-4 .list-group-item {
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: rgba(0, 0, 21, 0.125);
}

.per-4 .list-group-item svg {
    cursor: pointer;
}

.pri-4 .list-group-item svg {
    cursor: pointer;
}

//profile name
.nm-2 .ro-1 {
    padding: 0.5rem;
}

.nm-2 .card {
    margin-top: 1rem;
}

.nm-2 .crh-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.nm-2 .nmb-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.nm-2 .nmbt-1 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.nm-2 svg {
    color: #FF6600
}

.nm-2 .card-body .igp-1 {
    width: 30%;
}

.nm-2 .card-body .igp-1 .input-group-text {
    width: 100%;
}

.nm-2 .card-body input {
    border: 1px solid #d8dbe0;
    padding-left: 1rem;
    width: 60%;
}

.nm-2 .card-body small {
    padding-top: 0.5rem;
    color: red;
}

//profile emial
.eml-2 .ro-2 {
    padding: 0.5rem;
}

.eml-2 .card {
    margin-top: 1rem;
}

.eml-2 .crh-2 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.eml-2 .bdy-2 .card-header {
    background-color: #FF6600 !important;
    color: #eeeeee;
}

.eml-2 .nmb-2 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.eml-2 .nmbt-2 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.eml-2 svg {
    color: #FF6600
}

.eml-2 .card-body .igp-2 {
    width: 30%;
}

.eml-2 .card-body .igp-2 .input-group-text {
    width: 100%;
}

.eml-2 .card-body input {
    border: 1px solid #d8dbe0;
    padding-left: 1rem;
    width: 60%;
}

.eml-2 .card-body small {
    padding-top: 0.5rem;
    color: red;
}

// profile Company and title
.cmp-2 .ro-3 {
    padding: 0.5rem;
}

.cmp-2 .card {
    margin-top: 1rem;
}

.cmp-2 .crh-3 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.cmp-2 .nmb-3 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.cmp-2 .nmbt-3 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.cmp-2 svg {
    color: #FF6600
}

.cmp-2 .card-body .igp-3 {
    width: 30%;
}

.cmp-2 .card-body .igp-3 .input-group-text {
    width: 100%;
}

.cmp-2 .card-body input {
    border: 1px solid #d8dbe0;
    padding-left: 1rem;
    width: 60%;
}

.cmp-2 .card-body small {
    padding-top: 0.5rem;
    color: red;
}

// profile Contact
.cnt-2 .ro-4 {
    padding: 0.5rem;
}

.cnt-2 .card {
    margin-top: 1rem;
}

.cnt-2 .crh-4 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.cnt-2 .nmb-4 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.cnt-2 .nmbt-4 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.cnt-2 svg {
    color: #FF6600
}

.cnt-2 .card-body .igp-4 {
    width: 30%;
}

.cnt-2 .card-body .igp-4 .input-group-text {
    width: 100%;
}

.cnt-2 .card-body input {
    border: 1px solid #d8dbe0;
    padding-left: 1rem;
    width: 60%;
}

.cnt-2 .card-body small {
    padding-top: 0.5rem;
    color: red;
}

// privacy 
.pri-4 .list-group-item {
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: rgba(0, 0, 21, 0.125);
}

.modal-info {
    top: 15%;
}

.modal-danger {
    top: 15%;
}

.modal-secondary {
    top: 15%;
}

.modal-warning {
    top: 15%;
}

.modal-primary {
    top: 15%;
}

.modal-info .modal-dialog {
    border-radius: 10px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.modal-danger .modal-dialog {
    border-radius: 10px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.modal-secondary .modal-dialog {
    border-radius: 10px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.modal-warning .modal-dialog {
    border-radius: 10px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.modal-primary .modal-dialog {
    border-radius: 10px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}


.modal-info .btn-info {
    color: white;
    font-weight: bold;
}

.modal-danger .btn-danger {
    color: white;
    font-weight: bold;
}

.modal-secondary .btn-secondary {
    color: white;
    font-weight: bold;
}

.modal-warning .btn-warning {
    color: white;
    font-weight: bold;
}

.modal-primary .btn-primary {
    color: white;
    font-weight: bold;
}

.pri-4 .modal-info small {
    color: red;
    padding-top: 3px;
}

.pri-4 .modal-secondary .modal-title {
    color: white;
}

.pri-4 .modal-secondary .modal-title .close {
    color: black;
}

.pri-4 .modal-secondary small {
    color: red;
    padding-top: 3px;
}

.pri-4 .list-group svg {
    color: #FF6600;
}


//privacy change password
.chp-2 .ro-1 {
    padding: 0.5rem;
}

.chp-2 .card {
    margin-top: 1rem;
}

.chp-2 .crh-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.chp-2 .nmb-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.chp-2 .nmbt-1 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.chp-2 svg {
    color: #FF6600
}

.chp-2 .card-body .igp-1 {
    width: 30%;
}

.chp-2 .card-body .igp-1 .input-group-text {
    width: 100%;
}

.chp-2 .card-body input {
    border: 1px solid #d8dbe0;
    padding-left: 1rem;
    width: 60%;
}

.chp-2 .card-body small {
    padding-top: 0.5rem;
    color: red;
}


// privacy email varify
.emvf-2 .ro-2 {
    padding: 0.5rem;
}

.emvf-2 .card {
    margin-top: 1rem;
}

.emvf-2 .crh-2 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.emvf-2 .rmv-2 {
    background: red;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.emvf-2 .nmbt-2 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.emvf-2 .nmb-2 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.pri-4 .model-footer small {
    float: left;
    color: red;
}

.pnvf-2 .crh-3 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }

    .card-body {
        .list-group .list-group-item {
            .col {
                align-self: center;
            }
        }
    }
}

.reac-2 crh-5 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.reac-2 {
    .card-body {}
}

// language
// profile 
.lang-1 .list-group-item {
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
    border-color: rgba(0, 0, 21, 0.125);
}

.lang-1 .list-group-item svg {
    cursor: pointer;
}

.lang-1 .list-group-item svg {
    cursor: pointer;
}

//language signing
.sigD-2 .ro-1 {
    padding: 0.5rem;
}

.sigD-2 .card {
    margin-top: 1rem;
}

.sigD-2 .crh-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.sigD-2 .nmb-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.sigD-2 .nmbt-1 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.sigD-2 svg {
    color: #FF6600
}

.sigD-2 .form-actions {
    float: right;
}

.sigD-2 .card-body .igp-1 {
    width: 30%;
}

.sigD-2 .card-body .igp-1 .input-group-text {
    width: 100%;
}

.sigD-2 .card-body .custom-select {
    height: 2.5rem;
}

.sigD-2 .card-body input {
    border: 1px solid #d8dbe0;
    padding-left: 1rem;
    width: 60%;
}

.sigD-2 .card-body small {
    padding-top: 0.5rem;
    color: red;
}

//language sending
.senD-3 .ro-1 {
    padding: 0.5rem;
}

.senD-3 .card {
    margin-top: 1rem;
}

.senD-3 .crh-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.senD-3 .nmb-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.senD-3 .nmbt-1 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.senD-3 svg {
    color: #FF6600
}

.senD-3 .form-actions {
    float: right;
}

.senD-3 .card-body .igp-1 {
    width: 30%;
}

.senD-3 .card-body .igp-1 .input-group-text {
    width: 100%;
}

.senD-3 .card-body input {
    border: 1px solid #d8dbe0;
    padding-left: 1rem;
    width: 60%;
}

.senD-3 .card-body small {
    padding-top: 0.5rem;
    color: red;
}

.senD-3 .card-body .custom-select {
    height: 2.5rem;
}

//region
.regn-1 .ro-1 {
    padding: 0.5rem;
}

.regn-1 .card {
    margin-top: 1rem;
}

.regn-1 p {
    margin: 1rem;
}

.regn-1 .crh-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    small {
        color: #EEE !important;
        padding-top: 2px;
    }
}

.regn-1 .ch-1 {
    color: #4D148C;
    font-weight: bold;
    text-transform: uppercase;
}

.regn-1 .nmb-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.regn-1 .nmbt-1 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.regn-1 svg {
    color: #FF6600
}

.regn-1 .form-actions {
    float: right;
}

.regn-1 .card-body .igp-1 {
    width: 30%;
}

.regn-1 .checkbox {
    margin: 1rem;
}

.regn-1 .card-body .igp-1 .input-group-text {
    width: 100%;
}

.regn-1 .card-body small {
    padding-top: 0.5rem;
    color: red;
}

.regn-1 .card-body .custom-select {
    height: 2.5rem;
}

.regn-1 .crh-2 {
    background: #FF6600;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.regn-1 .form-check-input:checked {
    background-color: #FF6600;
    border-color: #FF6600;
}

// preference 
// .pref-1 {padding: 2rem;}
.pref-1 {
    border: 5px solid white;
}

.pref-1 .col2 {
    background: #4D148C;
    padding: 0px;
}

.pref-1 .col2 ul li a {
    color: white;
    font-size: 1rem;
}

.pref-1 .col2 ul li a:hover {
    // font-weight: 400 !important;
    outline: -webkit-focus-ring-color auto 1px !important;
}

.pref-1 .col2 ul li .active {
    background: #ebedef;
    color: #4D148C;
}

.pref-1 .col2 .card-header {
    background: lightgray;
    color: #4D148C;
    border-radius: 0px;
    padding: 1.5rem 2rem;
    font-size: 20px;
    text-align: center;
}

.pref-1 .col10 {
    padding: 0px;
}

.pref-1 .col10 .card-header {
    // margin-right: -15px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 0px;
    // padding: 0.92rem 2rem 0.3rem;
}

.pref-1 .col10 .card-body .card-header {
    background: #ebedef;
    padding: 0.5rem 1rem !important;
    text-transform: capitalize;

    h4 {
        color: #4D148C;
    }

    small {
        color: #666666 !important;
        float: right;
        margin-left: auto;
    }
}

.pref-1 .cnt-1 .brd-2 {
    display: flex;

    ul {
        height: 45px;
        border: 2px solid #eeeeee;
    }
}

// preference contact
.cnt-1 .ft-1 {
    background: 1px solid lightgray;
    // background: lightgray;
    text-align: end;
    padding: 0.5rem 0rem 0.5rem;
}

.cnt-1 .bt-2 {
    color: white;
    background-color: #4D148C;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
}

.cnt-1 svg {
    margin: 0rem 0.5rem 0.2rem;
}

.cnt-1 .modal-body small {
    color: red;
    float: left;
    margin: 0rem 0.5rem 0.5rem;
}

.cnt-1 .modal-body .hd-1 {
    display: flex;
    background: #ced2d8;
    margin: 0px -17px;
    padding: 0.5rem 2rem;
}

.cnt-1 .modal-body .hd-1 h4 {
    font-weight: bold;
    margin: 0px;
    align-self: center;
}

.cnt-1 .modal-body .hd-1 .btn {
    margin-left: auto;
    padding: 0.2rem 1rem;
    border: 1px solid #4D148C;
}

.cnt-1 .modal-body .hd-1 .btn:hover {
    background: #FF6600;
    color: white;
}

.cnt-1 .modal-body .hd-1 .btn svg {
    margin-right: 0.5rem;
}

.cnt-1 .modal-body .ph-1 {
    margin: 0.5rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 98%;
}

.cnt-1 .modal-body .ph-1 .col-2 {
    padding: 0px;
    margin: 0px;
    border-right: 1px solid lightgray;
    border-radius: 10px;
}

.cnt-1 .modal-body .ph-1 .cl5 {
    padding: 0px;
    margin: 0px;
    border-right: 1px solid lightgray;
    border-radius: 10px;
}

.cnt-1 .modal-body .ph-1 .custom-select {
    border: 0px;
}

.cnt-1 .modal-body .ph-1 .custom-select:focus {
    outline: 1px;
    box-shadow: none;
    border: 1px solid #4D148C;
}

.cnt-1 .modal-body .ph-1 .form-control:focus {
    outline: 1px;
    box-shadow: none;
    border: 1px solid #4D148C;
    border-radius: 5px;
    padding: 0.5rem 1rem;
}

.cnt-1 .modal-body .ph-1 .form-control {
    padding: 0px 0.5rem;
    margin: 0px;
    border: 0px;
}

.cnt-1 .modal-body .ph-1 .btn-danger {
    background: red;
    color: white;
    width: 10%;
}

.cnt-1 .modal-body .ph-1 .btn-danger:focus {
    box-shadow: none;
}

.cnt-1 .modal-body .ph-1 .btn-danger:hover {
    background: #e03232;
    color: black;
    font-weight: bold;
    width: 10%;
}

.pref-1 .col-10 .hd-2 {
    color: #4D148C;
    margin-right: -1px;
    border-radius: 0px;
    padding: 0.2rem 1.4rem;
    background: 1px solid lightgray;
    background: lightgray;
}

#contactTable .dang {
    background: red;
    color: white;
    cursor: pointer;
    border: 1px solid white;
    padding: 0.6rem !important;
    text-transform: uppercase;
}

#contactTable .sucs {
    background: green;
    color: white;
    cursor: pointer;
    border: 1px solid white;
    padding-top: 0.6rem !important;
    text-transform: uppercase;
}

#contactTable .card-header .form-check-input {
    outline: -webkit-focus-ring-color auto 1px;
    margin-left: -1rem !important;
    border: 1px solid white;
    height: 15px;
    width: 15px;
}

#contactTable .card-header .form-check {
    display: initial;
}

#contactTable .mra-1 {
    text-align: center;
}

#contactTable .mra-1 .form-check {
    display: initial;
    text-align: center;
}

#contactTable .dang:hover {
    color: black;
}

#contactTable .sucs:hover {
    color: black;
}

#contactTable .col {
    padding: 0px 10px;
}

#contactTable .insertNumber {
    padding: 5px 10px;
}

#showSelectedContactList .removeContact {
    font-weight: bold;
    cursor: pointer;
    font-size: 22px;
    border: 1px solid;
    border-radius: 50%;
    padding: 0px;
    height: 35px;
}

#showSelectedContactList .removeContact:hover {
    color: black !important;
}

//login page alert
.log-1 .almsc-1 {
    display: flex;
    padding-top: 3rem;
}

.log-1 .almdg-1 {
    display: flex;
    padding-top: 3rem;
}

.log-1 .almsc-1 h4 {
    display: inline-block;
    height: 65px;
}

.log-1 .almdg-1 h4 {
    display: inline-block;
    height: 65px;
}

//login page alert
.reg .almsc-1 {
    display: flex;
    padding-top: 3rem;
}

.reg .almdg-1 {
    display: flex;
    padding-top: 3rem;
}

.reg .almsc-1 h4 {
    display: inline-block;
    height: 80px;
}

.reg .almdg-1 h4 {
    display: inline-block;
    height: 100px;
}


//email varify page alert
.eml-1 .almsc-1 {
    display: flex;
    padding-top: 2rem;
}

.eml-1 .almdg-1 {
    display: flex;
    padding-top: 2rem;
}

.eml-1 .almsc-1 h4 {
    display: inline-block;
    height: 80px;
    text-align: center;
}

.eml-1 .almdg-1 h4 {
    display: inline-block;
    height: 80px;
    text-align: center;
}

.almsc-1 {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    outline: 0;
    background: rgba(0, 0, 21, 0.3) !important;
    justify-content: center;
}

.almsc-1 h4 {
    background: green;
    color: white;
    padding: 1rem 2rem;
    border-radius: 5px;
    border: 1px solid black;
}

.almdg-1 {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    outline: 0;
    background: rgba(0, 0, 21, 0.3) !important;
    justify-content: center;
}

.almdg-1 h4 {
    background: red;
    color: white;
    padding: 1rem 2rem;
    border-radius: 5px;
    border: 1px solid black;
}

.almif-1 {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    outline: 0;
    background: rgba(0, 0, 21, 0.3) !important;
    justify-content: center;
}

.almif-1 h4 {
    background: #FF6600;
    color: black;
    padding: 1rem 2rem;
    border-radius: 5px;
    border: 1px solid black;
}



// privacy phone varify
.pnvf-2 .ro-3 {
    padding: 0.5rem;
}

.pnvf-2 .card {
    margin-top: 1rem;
}

.pnvf-2 .crh-3 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.pnvf-2 .rmv-3 {
    background: red;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.pnvf-2 .nmbt-3 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.pnvf-2 .nmb-3 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

// privacy two step varification
.vfs-2 .ro-4 {
    padding: 0.5rem;
}

.vfs-2 .card {
    margin-top: 1rem;
}

.vfs-2 .crh-4 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.vfs-2 .fm-4 {
    display: flex;
}

.vfs-2 .fm-4 p {
    padding-top: 0.7rem;
    padding-left: 1rem;
}

.vfs-2 .swh-4 {
    height: 35px;
    width: 55px;
    margin-left: 1rem;
}

.vfs-2 .swh-4 span {
    background: rgba(0, 0, 21, 0.125);
    border: 1px solid rgba(0, 0, 21, 0.3);
}

.vfs-2 .swh-4 .c-switch-slider::before {
    width: 28px;
    height: 28px;
}

.vfs-2 .swh-4 .c-switch-input:checked~.c-switch-slider::before {
    transform: translateX(18px);
    background: #4D148C;
}

.vfs-2 .swh-4 .c-switch-input:focus~.c-switch-slider {
    background-color: rgba(0, 0, 21, 0.3);
    border-color: #4D148C;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

// privacy  retrieve account
.reac-2 .ro-5 {
    padding: 0.5rem;
}

.reac-2 .card {
    margin-top: 1rem;
}

.reac-2 .crh-5 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.reac-2 .fm-5 {
    display: flex;
}

.reac-2 .fm-5 p {
    padding-top: 0.7rem;
    padding-left: 1rem;
}

.reac-2 .rmv-5 {
    background: red;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.reac-2 .nmbt-5 {
    background: white;
    color: #4D148C;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.reac-2 .nmb-5 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.reac-2 .swh-5 {
    height: 35px;
    width: 55px;
    margin-left: 1rem;
}

.reac-2 .swh-5 span {
    background: rgba(0, 0, 21, 0.125);
    border: 1px solid rgba(0, 0, 21, 0.3);
}

.reac-2 .swh-5 .c-switch-slider::before {
    width: 28px;
    height: 28px;
}

.reac-2 .swh-5 .c-switch-input:checked~.c-switch-slider::before {
    transform: translateX(18px);
    background: #4D148C;
}

.reac-2 .swh-5 .c-switch-input:focus~.c-switch-slider {
    background-color: rgba(0, 0, 21, 0.3);
    border-color: #4D148C;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

.ifqa-5 .input-group-prepend {
    width: 20%;
}

.ifqa-5 .input-group-prepend .input-group-text {
    width: 100%;
}

// connected App 
.cnt-1 .ap-1 .card-title {
    margin: 0px;
    font-weight: bold;
}

// signature
.sig-1 .card-title {
    margin: 0px;
    font-weight: bold;
}

.sig-2 {
    border-left: 2px solid #005cb9;
    border-radius: 8px;
    display: block;
    height: 55px;
    margin: 4px 0;
    overflow: visible;
    position: relative;
    height: 100%;
    width: 100%;
}

.sig-2-title-top {
    font-weight: 700;
    top: -8px;
}

.sig-2 .sig-container {
    height: 55px;
    overflow: hidden;
    padding-left: 10px;
    position: initial;
    white-space: nowrap;
    width: 100%;
}

.sig-2 .sig {
    float: left;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.sig-2-title-bottom {
    bottom: -7px;
}

.sig-2-title-bottom,
.sig-2-title-top {
    font-size: 10px;
    left: 21px;
    position: absolute;
    line-height: 10px;
}

.sig-2::before {
    border-top-left-radius: 8px;
    border-top-width: 2px !important;
    top: -2px;
}

.sig-2::after {
    border-bottom-left-radius: 8px;
    border-bottom-width: 2px !important;
    bottom: -2px;
}

.sig-2::after,
.sig-2::before {
    border: 0 solid #005cb9;
    content: '';
    display: block;
    height: 38px;
    left: -1px;
    position: absolute;
    width: 20px;
}

.sig-1 .nmb-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.sig-1 .nmbs-1 {
    background: #c4c9d0;
    color: black;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid black;
    margin-right: 5px;
}

.sig-1 .modal-secondary .modal-dialog {
    max-width: 1000px;
}

.sig-1 .modal-dialog .sgr-1 {
    background: #4D148C;
    border-radius: 10px;
    border: 1px solid black;
    margin: 0px 0.5rem 1rem 0.5rem;
    padding: 0.7rem;
}

.sig-1 .modal-dialog .sgr-1 form {
    display: flex;
}

.sig-1 .modal-dialog .sgr-1 .fln-3 {
    margin: 0.3rem;
}

.sig-1 .modal-body .card-header {
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.sig-1 .modal-body .card-header .nav-tabs {
    border: none !important;
    margin-top: 0.2rem;
    margin-left: auto;
}

.sig-1 .modal-body .card-header .nav-tabs .nav-item {
    font-size: 16px;
}

.sig-1 .modal-body .card-header .nav-tabs .nav-item a {
    color: #ebedef;
    padding: 0.5rem 0.8rem;
}

.sig-1 .modal-body .card-header .nav-tabs .nav-item a.active {
    color: #4D148C !important;
}

.sig-1 .sgt-1 {
    display: contents;
}

.sig-1 .sigm-1 .card-body {
    padding: 0.5rem;
}

.sig-1 .sigm-1 small {
    color: #d8dbe0;
}

.sig-1 .sigm-1 .sgr-2 {
    margin-top: auto;
    margin-bottom: auto;
}

.sig-1 .tb-1 .row {
    padding: 0.5rem 0px;
}

.sig-1 .tb-1 {
    height: 160px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sig-1 .tb-1::-webkit-scrollbar {
    display: none;
}

.sig-1 .tb-1 .sig-container input {
    background-color: white;
    border: none;
    margin-right: 5rem;
}


@font-face {
    font-family: font_1;
    src: url('../assets/signature_font/font_1.woff')
}

@font-face {
    font-family: font_2;
    src: url('../assets/signature_font/font_2.woff')
}

@font-face {
    font-family: font_3;
    src: url('../assets/signature_font/font_3.woff')
}

@font-face {
    font-family: font_4;
    src: url('../assets/signature_font/font_4.woff')
}

@font-face {
    font-family: font_5;
    src: url('../assets/signature_font/font_5.woff')
}

@font-face {
    font-family: font_6;
    src: url('../assets/signature_font/font_6.woff')
}

@font-face {
    font-family: font_7;
    src: url('../assets/signature_font/font_7.woff')
}

@font-face {
    font-family: font_8;
    src: url('../assets/signature_font/font_8.woff')
}

.sig-1 .tb-1 .sig-container .font_1 {
    font-family: font_1;
    font-weight: bold;
    text-indent: 10px;
    font-size: x-large;
    padding-left: 1rem;
    line-height: 0.5rem;
    padding-top: 0.1rem
}

.sig-1 .tb-1 .sig-container .font_2 {
    font-family: font_2;
    font-weight: bold;
    text-indent: 10px;
    font-size: x-large;
    padding-left: 1rem;
    line-height: 2rem;
    padding-top: 0.7rem
}

.sig-1 .tb-1 .sig-container .font_3 {
    font-family: font_3;
    font-weight: bold;
    text-indent: 10px;
    font-size: x-large;
    padding-left: 1rem;
    line-height: 2rem;
    padding-top: 0.7rem
}

.sig-1 .tb-1 .sig-container .font_4 {
    font-family: font_4;
    font-weight: bold;
    text-indent: 10px;
    font-size: x-large;
    padding-left: 1rem;
    line-height: 2rem;
    padding-top: 0.7rem
}

.sig-1 .tb-1 .sig-container .font_5 {
    font-family: font_5;
    font-weight: bold;
    text-indent: 10px;
    font-size: x-large;
    padding-left: 1rem;
    line-height: 0rem;
    padding-top: 0.7rem
}

.sig-1 .tb-1 .sig-container .font_6 {
    font-family: font_6;
    font-weight: bold;
    text-indent: 10px;
    font-size: x-large;
    padding-left: 1rem;
    line-height: 0.5rem;
    padding-top: 0.1rem
}

.sig-1 .tb-1 .sig-container .font_7 {
    font-family: font_7;
    font-weight: bold;
    text-indent: 10px;
    font-size: x-large;
    padding-left: 1rem;
    line-height: 2rem;
    padding-top: 0.7rem
}

.sig-1 .tb-1 .sig-container .font_8 {
    font-family: font_8;
    font-weight: bold;
    text-indent: 10px;
    font-size: x-large;
    padding-left: 1rem;
    line-height: 0.5rem;
    padding-top: 0.7rem
}

.sig-1 .tb-2 .sel-1 {
    max-width: 20%;
    text-align: left;
    padding-top: 1.5rem;
    padding-left: 2rem;
}

.sig-1 .tb-2 .sel-1 .custom-control {
    padding-left: 2.5rem;
}

.sig-1 .tb-2 .can-1 {
    height: 200px;
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #c4c9d0;
    border-radius: 5px;
}

.sig-1 .tb-2 .can-2 {
    height: 200px;
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #c4c9d0;
    border-radius: 5px;
}

.sig-1 .tb-2 .mg-1 {
    margin-top: 0.5rem;
}

.sig-1 .tb-2 .rw-1 .btn {
    padding: 0.6rem 0.7rem;
    margin: 0.5rem 0.6rem;
}

.sig-1 .tb-2 .rw-1 .btn-dark-active {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0.25rem #3C4649;
}

.sig-1 .tb-2 .rw-1 .btn-primary-active {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem #3184FD;
}

.sig-1 .tb-2 .rw-1 .btn-success-active {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 0.25rem #3C996E;
}

.sig-1 .tb-2 .rw-1 .btn-danger-active {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem #E15361;
}

.sig-1 .tb-2 .cl-1 {
    font-weight: bold;
    background: #4D148C;
    color: white;
    padding: 0.3rem 0.4rem 0.3rem 0.7rem;
    width: inherit;
}

.sig-1 .tb-2 .cl-1 .c-icon {
    padding-left: 0.5rem;
    width: 2rem;
}

.sig-1 .ls-1 {
    padding: 1rem;
}

.sig-1 .ls-1 .rmv-4 {
    background: red;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.sig-1 .ls-1 .mra-1 {
    margin: auto 0px;
    text-align: center;
}

.sig-1 .tb-3 .drw-1 {
    padding: 0.5rem;
    border: 1px solid #c4c9d0;
    border-radius: 5px;
    margin: 0.2rem 0.5rem 0.8rem;
}

.sig-1 .tb-3 .fileUploader {
    box-shadow: none;
}

.sig-1 .tb-3 .drw-1 .sigUpload {
    max-width: 100%;
    max-height: 200px;
    border-radius: 5px;
}

// stamps
.stp-1 .ls-2 {
    padding: 1rem;
}

.stp-1 .ls-2 .rmv-4 {
    background: red;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
}

.stp-1 .ls-2 .mra-1 {
    margin: auto 0px;
    text-align: center;
}

.stp-1 .ls-2 .mra-2 {
    border: 1px solid #c4c9d0;
    border-radius: 5px;
}

.stp-1 .nmb-1 {
    background: #4D148C;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
}

.stp-1 .sds-1 .drw-1 {
    padding: 0.5rem;
}

.stp-1 .sds-1 .fileContainer {
    box-shadow: none;
    border: 1px solid #c4c9d0;
    border-radius: 5px;
}

.stp-1 .sds-1 .drw-1 .stampUpload {
    max-width: 100%;
    max-height: 200px;
    border-radius: 5px;
}

.stp-1 .sds-1 .fln-3 {
    margin: 0.3rem;
}

.stp-1 .sds-1 .card {
    padding: 1rem 1rem 1rem 0.7rem;
}

.stp-1 .sds-1 .drw-1 .lb-1 {
    float: left;
    padding: 0.5rem 1rem;
}

.stp-1 .sds-1 .drw-1 .fam-4 {
    padding: 1rem;
    display: flex;
}

.stp-1 .sds-1 .drw-1 .fam-4 .lb-2 {
    margin-top: 0.2rem;
}

.stp-1 .sih-1 {
    height: 35px;
    width: 55px;
    margin-left: 1rem;
}

.stp-1 .sih-1 span {
    background: rgba(0, 0, 21, 0.125);
    border: 1px solid rgba(0, 0, 21, 0.3);
}

.stp-1 .sih-1 .c-switch-slider::before {
    width: 28px;
    height: 28px;
}

.stp-1 .sih-1 .c-switch-input:checked~.c-switch-slider::before {
    transform: translateX(18px);
    background: #4D148C;
}

.stp-1 .sih-1 .c-switch-input:focus~.c-switch-slider {
    background-color: rgba(0, 0, 21, 0.3);
    border-color: #4D148C;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}




// plans
.pys-1 .list-group-item {
    display: inline-flex;
    padding: 0.5rem 1.25rem;
}

.pys-1 .list-group-item p {
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.pys-1 .spr-1 {
    border-top: 1px solid rgba(0, 0, 21, 0.125);
}

.pys-1 small {
    padding: 0.2rem 0.5rem 0.1rem;
}

.yrl-1 .list-group-item {
    display: inline-flex;
    padding: 0.5rem 1.25rem;
}

.yrl-1 .list-group-item p {
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.yrl-1 .spr-1 {
    border-top: 1px solid rgba(0, 0, 21, 0.125);
}

.pnl-1 .pna-1 {
    display: grid;
    columns: white;
    border-radius: 5px;
    padding: 1rem;
    background: #d8dbe0;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.pnl-1 .pna-1 p {
    color: black;
    margin-bottom: 0px;
}

.pnl-1 .pna-1 .modal-header {
    border-bottom: 1px solid #c4c9d0;
    background: white;
}

.pnl-1 .pna-1 .modal-title {
    display: contents;
    font-size: 1.5rem;
}

.pnl-1 .pna-1 .spl-5 h4 b {
    color: #321fdb;
}

.pnl-1 .pna-1 .spl-5 h5 b {
    color: black;
}




//Prepare
.pre-1 {
    width: auto !important;
    max-width: none !important;
}

.pre-1 .crd-1 {
    background: #321fdb;
    color: white;
    padding-bottom: 0px;
    border: none;
    border-radius: 0px;
    padding-top: 10px;
}

.pre-1 .crd-1 .nav-tabs .nv-1 {
    margin-left: auto;
    border: none;
}

.pre-1 .crd-1 .nav-tabs .nv-4 {
    margin-right: auto;
}

.pre-1 .crd-1 .nav-tabs .nav-link {
    color: white;
    padding: 0.5rem 1rem;
}

.pre-1 .crd-1 .nav-tabs .nav-link:focus {
    border: none;
}

.pre-1 .crd-1 .nav-tabs .nav-link:hover {
    border: none;
}

.pre-1 .crd-1 .nav-tabs {
    border: none;
}

.pre-1 .crd-1 .nav-tabs .nav-item {
    margin-bottom: -2px;
}

.pre-1 .crd-1 .nav-tabs .nav-item b {
    cursor: pointer;
}

.pre-1 .crd-1 .nav-tabs .nav-item b:hover {
    color: lightgray;
}

.pre-1 .crd-1 .nav-tabs .nav-link.active {
    color: #321fdb;
    background-color: white;
    border: none;
}

.pre-1 .crdb-1 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.pre-1 .card .dsable {
    pointer-events: none;
    opacity: 0.6;
}

// First Tab "Add Document"
.pre-1 .tb-1 .card {
    padding: 0px;
    margin: 2rem;
}

.pre-1 .tb-1 .card-header {
    color: #321fdb;
    padding: 1rem 2rem;
    background: lightgray;
    border: #999;
    border-radius: 0px;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;

    .btn {
        float: right
    }
}

.pre-1 .tb-1 .card-footer {
    display: flex;
    padding: 1rem 2rem;
    background: lightgray;
    border: #999;
    border-radius: 0px;
}

.pre-1 .tb-1 .card-footer .form-check {
    align-self: center;
}

.pre-1 .tb-1 .card-footer .mr-left {
    margin-left: auto !important;
}

.pre-1 .tb-1 .card-footer .form-check-input {
    width: 1rem;
    height: 1rem;
}

.pre-1 .tb-1 .card-footer .form-check-label {
    font-size: 17px;
}

.pre-1 .tb-1 .card-footer .btn {
    background: #321fdb;
    color: white;
    padding: 0.3rem 1rem;
    margin: 0px 1rem;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}


.pre-1 .tb-1 .card-body .fm-1 .file-input-label {
    font-size: 18px;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background: #321fdb;
    color: lightgray;
    width: inherit;
    margin: 1.25rem 0.5rem 0.2rem;
}

.pre-1 .tb-1 .card-body .fm-1 .form-control-file {
    outline: 2px dashed #999;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}

.pre-1 .tb-1 .card-body .fm-1 .form-control-file:focus {
    outline: 3px groove #321fdb;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
}

.pre-1 .tb-1 .card-body .fm-1 {
    position: relative
}

.pre-1 .tb-1 .card-body .fm-1:after {
    pointer-events: none;
    position: absolute;
    top: 100px;
    left: 0;
    width: 50px;
    right: 0;
    height: 50px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.pre-1 .tb-1 .card-body .fm-1:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    font-size: 16px;
    display: block;
    margin: 0 auto;
    color: #321fdb;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}

.pre-1 .tb-1 .card-body .fm-2 .file-input-label {
    font-size: 18px;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background: #321fdb;
    color: lightgray;
    width: inherit;
    margin: 1.25rem 0.5rem 0.2rem;
}


.pre-1 .tb-2 {
    margin-top: 1.5rem;
    border: 1px solid rgb(216, 219, 224);
    padding: 0px;
}

.pre-1 .tb-2 .card {
    padding: 0px;
    border: none;
    margin: 0px 1rem;
}

.pre-1 .tb-2 .card-header {
    color: #321fdb;
    padding: 1rem 2rem;
    background: lightgray;
    border: #999;
    border-radius: 0px;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.pre-1 .tb-2 .card-body .rmv-4 {
    background: red;
    color: white;
    font-weight: bold;
    float: right;
    padding: 0.5rem 1rem;
    border: 1px solid #4D148C;
    margin-right: 5px;
}

.pre-1 .tb-2 .card-footer {
    display: flex;
    padding: 1rem 2rem;
    background: lightgray;
    border: #999;
    border-radius: 0px;
}

.pre-1 .tb-2 .rcp-1 .hd-0 {
    font-size: 18px;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background: #321fdb;
    color: lightgray;
    width: inherit;
    margin: 0px 0.5rem;
}

.pre-1 .tb-2 .rcp-1 .hd-1 {
    display: flex;
    padding: 0.5rem 2rem;
    margin-left: 1rem;
}

.pre-1 .tb-2 .rcp-1 .hd-1 .col-8 {
    text-transform: uppercase;
    color: gray;
    font-weight: bold;
}

.pre-1 .tb-2 .rcp-1 .hd-2 {
    font-size: 18px;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background: #321fdb;
    color: lightgray;
    width: inherit;
    margin: 0px 0.5rem;
}

.pre-1 .tb-2 .sod-1 {
    display: flex;
    padding: 0.5rem 2rem 1.5rem;
    border-radius: 0px;
    margin-left: 1rem;
}

.pre-1 .tb-2 .sod-1 .form-check {
    align-self: center;
}

.pre-1 .tb-2 .sod-1 .form-check-input {
    width: 1rem;
    height: 1rem;
}

.pre-1 .tb-2 .sod-1 .form-check-label {
    font-size: 16px;
}

.pre-1 .tb-2 .sod-1 .btn {
    background: lightgrey;
    color: white;
    padding: 0.3rem 1rem;
    margin: 0px 1rem;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.pre-1 .tb-2 .ls-1 .row .col {
    align-self: center;
}

.pre-1 .tb-2 .sod-2 {
    font-size: 18px;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background: #321fdb;
    color: lightgray;
    width: inherit;
    margin: 0px 0.5rem;
}

.pre-1 .tb-2 .sod-2 .btn {
    background: white;
    color: #321fdb;
    padding: 0.3rem 1rem;
    margin-left: auto;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
}

.pre-1 .tb-2 .card-footer .fs-1 {
    margin-left: auto !important;
}

.pre-1 .tb-2 .card-footer .btn {
    color: white;
    padding: 0.3rem 1rem;
    margin: 0px 1rem;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid darkgray;
    border-radius: 5px;
}




.adrcpt-2 .lb-3 {
    float: left;
    padding-top: 2px;
}

.adrcpt-2 .form-group .col-md-1 {
    margin-top: 0.5rem;
    outline: 0px
}

.adrcpt-2 .c-switch-pill {
    margin: 0px 1rem;
}

.adrcpt-2 .c-switch-pill .c-switch-slider {
    border: 1px solid;
}

.adrcpt-2 .row {
    width: -webkit-fill-available;
}

.adrcpt-2 .rc-2 {
    text-transform: uppercase;
    padding: 0.3rem 0.5rem;
    color: #4D148C;
    font-weight: bold;
    background: none;
    border: 0px;
}

.adrcpt-2 .rc-5 {
    font-weight: normal;
    color: #666666;
    text-transform: lowercase;
}

.adrcpt-2 .rc-2 option {
    color: gray;
}

.adrcpt-2 .rc-2 option:hover {
    background: #321fdb;
}

.adrcpt-2 .form-check-input {
    margin-top: 0.5rem;
}

// .adrcpt-3 .disable { cursor: not-allowed; color: #eee;}
.adrcpt-3 small {
    margin-right: auto;
    font-weight: bold;
}

.pre-1 .almsc-1 {
    display: flex;
    padding-top: 2rem;
}

.pre-1 .almdg-1 {
    display: flex;
    padding-top: 2rem;
}

.pre-1 .almsc-1 h4 {
    display: inline-block;
    height: 80px;
}

.pre-1 .almdg-1 h4 {
    display: inline-block;
    height: 80px;
}






// .pre-1 .tb-3 .rw-1 .rep-1{margin: 0px 1rem; width: 70%; padding: 0rem 1rem; height: 2rem;}
// .pre-1 .tb-3 .rw-1 .zm-1{height: 1.5rem;padding-top: 2px;padding-bottom: 5PX;}
// .pre-1 .tb-3 .rw-1 .c-icon{ width: 1.3rem; margin: 0.3rem; height: 1rem;}
// .pre-1 .tb-3 .rw-1 .mid-1 { display: contents;}
// .pre-1 .tb-3 .rw-1 .mid-1 .col{align-self: center;}
// .pre-1 .tb-3 .rw-1 .mid-2 {align-self: center;} 
// .pre-1 .tb-3 .rw-1  .undo {cursor: not-allowed;}
// .pre-1 .tb-3 .rw-1  .redo {cursor: not-allowed;}
// .pre-1 .tb-3 .rw-1  .copy {cursor: not-allowed;}
// .pre-1 .tb-3 .rw-1  .paste {cursor: not-allowed;}
// .pre-1 .tb-3 .rw-1  .delete {cursor: not-allowed;}
// .pre-1 .tb-3 .rw-1  .add {cursor: not-allowed;}
// .pre-1 .tb-3 .rw-1  .commands {cursor: not-allowed;}
// .pre-1 .tb-3 .rw-1  .clipboard {cursor: not-allowed;}

.pre-1 .tb-3 .rw-2 {
    padding-top: 0px;
    margin: 0px -15px;
}

// .pre-1 .tb-3 .rw-2 .col-md-3{margin-top: -2.32rem;}
.pre-1 .tb-3 .p-0 .ro-1 {
    display: grid;
    width: inherit;
    margin: 10px 0px;
    justify-content: center;
}

.pre-1 .tb-3 .p-0 .ro-1 select {
    border-radius: 5px;
    background: #fff;
    font-size: 16px;
}

.pre-1 .tb-3 .rw-2 .sr-1 {
    margin: 0.42rem 1rem;
}

.pre-1 .tb-3 .rw-2 .sr-1 input {
    padding: 0px 0.5rem;
    height: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .r-2 {
    margin: 6px 2px 5px 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.pre-1 .tb-3 .rw-2 .r-2 .cl-2 {
    overflow-y: scroll;
    /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pre-1 .tb-3 .rw-2 .r-2 .cl-2::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pre-1 .tb-3 .rw-2 .r-2 .cl-2 {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.pre-1 .tb-3 .rw-2 .nav {
    background: #EBEBEB;
    color: white;
    padding: 0.3rem;
}

.pre-1 .tb-3 .rw-2 .nav-tabs .nav-link {
    color: #4D148C;
}

.pre-1 .tb-3 .rw-2 .nav-tabs .nav-link:focus {
    border: none;
}

.pre-1 .tb-3 .rw-2 .nav-tabs .nav-link:hover {
    border: none;
}

.pre-1 .tb-3 .rw-2 .nav-tabs {
    border: none;
    padding-bottom: 0rem;
    justify-content: center;
}

.pre-1 .tb-3 .rw-2 .nav-tabs .nav-item {
    margin-right: -0.5rem, ;
    text-align: center;
    color: #4D148C;
    // font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .nav-tabs .nav-link.active {
    color: #FF6600;
    // font-weight: bold;
    background-color: white;
    border: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.pre-1 .tb-3 .rw-2 .cl-1 {
    display: contents;
}

//sperate css for testing
// .pre-1 .tb-3 .rw-2 .cl-2 {padding: 7px 20px 5px 24px; }
.pre-1 .tb-3 .rw-2 .cl-2 .listItem {
    border: none;
    user-select: none;
    list-style: none;
    touch-action: none;
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    display: table;
    transition: background-color 0.3s;
}

.pre-1 .tb-3 .rw-2 .cl-2 .dropzone {
    background-color: #bfe4ff;
    border: dashed 4px transparent;
    border-radius: 4px;
    margin: 10px auto 30px;
    padding: 10px;
    width: 80%;
    transition: background-color 0.3s;
    height: 140px;
    position: inherit !important;
}

.pre-1 .tb-3 .rw-2 .cl-2 .drop-active {
    border-color: #aaa;
}

.pre-1 .tb-3 .rw-2 .cl-2 .drop-target {
    background-color: #29e;
    border-color: #fff;
    border-style: solid;
}

.pre-1 .tb-3 .rw-2 .cl-2 .listItem.can-drop {
    display: flex;
    color: #000;
    background-color: #4e4;
}

.pre-1 .tb-3 .rw-2 .cl-2 .rw-1 .rep-1 {
    text-transform: uppercase;
    margin: 0px 1rem;
    width: -webkit-fill-available;
    padding: 0rem 1rem;
    height: 3rem;
    margin: 1rem 2rem 1rem 3rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .rw-1 .zm-1 {
    height: 1.5rem;
    padding-top: 2px;
    padding-bottom: 5PX;
}

.pre-1 .tb-3 .rw-2 .cl-2 .rw-1 .c-icon {
    width: 1.3rem;
    margin: 0.3rem;
    height: 1rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .r-1 {
    text-align: center;
    padding: 0.7rem;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #eeeeee;
}

.pre-1 .tb-3 .rw-2 .cl-2 .r-1 .hd-1 {
    font-size: 18px !important;
    padding: 0px;
    margin: 0px auto;
}

.pre-1 .tb-3 .rw-2 .cl-2 .ul-1 {
    list-style-type: none;
    border-bottom: 3px solid #d8dbe0;
    border-radius: 0px !important;
    margin: 0px 2.5rem;
    padding: 0.3rem 0px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .ul-2 {
    list-style-type: none;
    border-radius: 0px !important;
    margin: 0px 2.5rem;
    padding: 0.5rem 0px;
}


.pre-1 .tb-3 .rw-2 .cl-2 .hd-1 {
    margin: 0.5rem auto;
    font-weight: bold;
    text-align: center;
}

.pre-1 .tb-3 .rw-2 .cl-2 .ul-1 {
    border-bottom: 3px solid #d8dbe0;
    border-radius: 0px !important;
    margin: 0px;
    padding: 0.3rem 0px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .ul-2 {
    border-radius: 0px !important;
    margin: 0px;
    padding: 0.5rem 0px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_0 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #f5c431;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_1 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #81afb8;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_2 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #91789e;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_3 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #70948d;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_4 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #e69d73;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_5 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #6c89a6;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_6 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #aeb86e;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_7 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #db765c;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_8 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #b38fb3;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_9 {
    display: block;
    margin: 2px 0px;
    border-left: 5px solid #d98298;
    padding: 0.3rem 1.3rem;
    font-size: 13px;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_0S {
    background: #f5c431;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_0S {
    background: #f5c431;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_1S {
    background: #81afb8;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_2S {
    background: #91789e;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_3S {
    background: #70948d;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_4S {
    background: #e69d73;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_5S {
    background: #6c89a6;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_6S {
    background: #aeb86e;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_7S {
    background: #db765c;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_8S {
    background: #b38fb3;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_9S {
    background: #d98298;
    display: block;
    margin: 2px 0px;
    border-left: 5px solid black;
    padding: 0.3rem 1.3rem;
    color: black;
    font-size: 13px;
    font-weight: bold;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_0 .svg-inline--fa {
    color: #f5c431;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_1 .svg-inline--fa {
    color: #81afb8;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_2 .svg-inline--fa {
    color: #91789e;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_3 .svg-inline--fa {
    color: #70948d;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_4 .svg-inline--fa {
    color: #e69d73;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_5 .svg-inline--fa {
    color: #6c89a6;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_6 .svg-inline--fa {
    color: #aeb86e;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_7 .svg-inline--fa {
    color: #db765c;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_8 .svg-inline--fa {
    color: #b38fb3;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_9 .svg-inline--fa {
    color: #d98298;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_0S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_1S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_2S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_3S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_4S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_5S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_6S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_7S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_8S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}

.pre-1 .tb-3 .rw-2 .cl-2 .recpt_9S .svg-inline--fa {
    color: black;
    font-size: 1.2rem;
    margin: 0px 0.5rem 0px 0.2rem;
    height: 1rem;
    width: 1.5rem;
}




// .pre-1 .tb-3 .col-md-10 .viewer-page-layer { height: 1500px !important; width: 1200px !important;}

.pre-1 .tb-3 .col-md-10 .viewer-layout-main {
    overflow-y: scroll;
    overflow-x: scroll;
}

.pre-1 .tb-3 .col-md-10 .crosshair {
    cursor: crosshair;
}

.pre-1 .tb-3 .col-md-10 .viewer-layout-container {
    margin-top: 0.1rem;
}

.pre-1 .tb-3 .col-md-10 .viewer-layout-toolbar {
    border: 0px;
}



.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .listItem {
    font-size: 11px;
    font-weight: bold;
    padding: 0.5rem;
    text-align: center;
    display: grid !important;
    text-transform: uppercase;
    border: 1px solid black;
    border-radius: 5px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .listItem .svg-inline--fa {
    font-size: 1rem;
    margin: 2px auto;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_0 {
    background: #f5c431;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_1 {
    background: #81afb8;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_2 {
    background: #91789e;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_3 {
    background: #70948d;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_4 {
    background: #e69d73;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_5 {
    background: #6c89a6;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_6 {
    background: #aeb86e;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_7 {
    background: #db765c;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_8 {
    background: #b38fb3;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_9 {
    background: #d98298;
}


.pre-1 .tb-3 .col-md-10 .Srecpt_0 {
    cursor: move;
    border: 3px solid #f5c431 !important;
    color: #f5c431;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_1 {
    cursor: move;
    border: 3px solid #81afb8 !important;
    color: #81afb8;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_2 {
    cursor: move;
    border: 3px solid #91789e !important;
    color: #91789e;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_3 {
    cursor: move;
    border: 3px solid #70948d !important;
    color: #70948d;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_4 {
    cursor: move;
    border: 3px solid #e69d73 !important;
    color: #e69d73;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_5 {
    cursor: move;
    border: 3px solid #6c89a6 !important;
    color: #6c89a6;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_6 {
    cursor: move;
    border: 3px solid #aeb86e !important;
    color: #aeb86e;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_7 {
    cursor: move;
    border: 3px solid #db765c !important;
    color: #db765c;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_8 {
    cursor: move;
    border: 3px solid #b38fb3 !important;
    color: #b38fb3;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_9 {
    cursor: move;
    border: 3px solid #d98298 !important;
    color: #d98298;
    box-sizing: border-box;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_0 .resizer {
    cursor: move;
    border: 3px solid #f5c431;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_1 .resizer {
    cursor: move;
    border: 3px solid #81afb8;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_2 .resizer {
    cursor: move;
    border: 3px solid #91789e;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_3 .resizer {
    cursor: move;
    border: 3px solid #70948d;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_4 .resizer {
    cursor: move;
    border: 3px solid #e69d73;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_5 .resizer {
    cursor: move;
    border: 3px solid #6c89a6;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_6 .resizer {
    cursor: move;
    border: 3px solid #aeb86e;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_7 .resizer {
    cursor: move;
    border: 3px solid #db765c;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_8 .resizer {
    cursor: move;
    border: 3px solid #b38fb3;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .Srecpt_9 .resizer {
    cursor: move;
    border: 3px solid #d98298;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_0 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #f5c431;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_1 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #81afb8;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_2 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #91789e;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_3 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #70948d;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_4 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #e69d73;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_5 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #6c89a6;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_6 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #aeb86e;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_7 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #db765c;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_8 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #b38fb3;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}

.pre-1 .tb-3 .col-md-10 .viewer-annotation-layer .recpt_9 .resizers {
    width: 100%;
    height: 100%;
    border: 3px solid #d98298;
    box-sizing: border-box;
    text-align: center;
    display: grid !important;
    min-width: 20%;
    min-height: 20%;
}


.pre-1 .tb-3 .col-md-10 .resize .resizer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    border: 3px solid #4286f4;
    position: absolute;
}

.pre-1 .tb-3 .col-md-10 .resize .resizer.top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize;
}

.pre-1 .tb-3 .col-md-10 .resize .resizer.top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize;
}

.pre-1 .tb-3 .col-md-10 .resize .resizer.bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.pre-1 .tb-3 .col-md-10 .resize .resizer.bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}

.pre-1 .tb-3 .rw-3 {
    background: #321fdb;
    color: white;
    padding: 5px;
    margin: 0px -20px;
    border: none;
    border-radius: 0px;
}

.pre-1 .tb-3 .rw-3 .btn-1 {
    margin-left: auto;
    margin-right: 1rem;
}

.pre-1 .tb-3 .rw-3 .btn-2 {
    margin-right: 3rem;
}

.pre-1 .tb-4 .rw-3 {
    background: #321fdb;
    color: white;
    padding: 5px;
    margin: 0px -20px;
    border: none;
    border-radius: 0px;
}

.pre-1 .tb-4 .rw-3 .btn-1 {
    margin-left: auto;
    margin-right: 1rem;
}

.pre-1 .tb-4 .rw-3 .btn-2 {
    margin-right: 3rem;
}

.pre-1 .tb-4 .rw-2 .r-1 {
    text-align: center;
    padding: 0.7rem;
    font-weight: bold;
    background-color: #eeeeee;
    margin: 0.3rem;
    border: 0px;
}

.pre-1 .tb-4 .rw-2 .r-1 h3 {
    margin: 0px auto;
    font-size: 1.15rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    color: #321fdb;
}

.pre-1 .tb-4 .rw-2 .r-2 {
    text-align: center;
    padding: 0rem;
    background-color: #eeeeee;
    margin: 0.3rem;
    border: 0px;
}

.pre-1 .tb-4 .rw-2 .r-2 .btn-2 {
    border-radius: 5px;
    font-size: 1.15rem;
    text-transform: uppercase;
    background-color: #eeeeee;
    border: 0px;
    font-weight: bold;
    color: #321fdb;
}

.pre-1 .tb-4 .rw-2 .adc-2 .lb-4 {
    float: left;
    text-transform: uppercase;
    padding: 0.1rem 0.5rem;
    color: #321fdb;
    font-weight: bold;
}

.pre-1 .tb-4 .rw-2 .adc-2 .rc-3 {
    padding: 0.1rem 0.5rem;
    font-size: 16px;
}

.pre-1 .tb-4 .rw-2 .adc-2 .form-check-input {
    margin-top: 0.5rem;
}

.pre-1 .tb-4 .rw-2 .adc-2 .form-group {
    margin-bottom: 1.3rem;
}

.pre-1 .tb-4 .rw-2 .adc-2 small {
    color: red;
    float: right;
    padding: 0.3rem;
}

.pre-1 .tb-4 .rw-2 .adc-2 .lb-2 {
    margin-top: 2rem;
    margin-bottom: 0px;
}

.pre-1 .tb-4 .rw-2 .adc-2 p {
    padding: 0.1rem 0.5rem;
    text-align: end;
    color: #666;
    margin: 0px;
}

.pre-1 .tb-4 .col-md-9 .viewer-layout-container {
    border: 0px;
    margin-top: 0.1rem;
}

.pre-1 .tb-4 .col-md-9 .viewer-layout-toolbar {
    border: 0px;
}

.viewer-inner-page {
    padding: 10px 0px;
}

// .viewer-layout-main{overflow-x: hidden !important;}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .listItem {
    font-size: 14px;
    font-weight: bold;
    padding: 0.5rem;
    text-align: center;
    display: grid !important;
    text-transform: uppercase;
    border: 1px solid black;
    border-radius: 5px;
    color: black;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .listItem .svg-inline--fa {
    font-size: 2rem;
    margin: 2px auto;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_0 {
    background: #f5c431;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_1 {
    background: #81afb8;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_2 {
    background: #91789e;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_3 {
    background: #70948d;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_4 {
    background: #e69d73;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_5 {
    background: #6c89a6;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_6 {
    background: #aeb86e;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_7 {
    background: #db765c;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_8 {
    background: #b38fb3;
}

.pre-1 .tb-4 .col-md-9 .viewer-annotation-layer .recpt_9 {
    background: #d98298;
}

.sndIfo-1 .card-header {
    text-align: left;
    font-weight: bold;
    background: lightgray;
    color: #321fdb;
}

.sndIfo-1 .rw-1 {
    border: 1px solid lightgray;
    margin: 1rem 0px;
    padding: 0px;
    border-radius: 5px;
}

.sndIfo-1 .rw-1 h4 {
    margin: 0px;
    align-self: center;
}

.sndIfo-1 .rw-1 .col-2 {
    padding: 0.5rem 0px;
    margin: 0px;
    background: #ced2d8;
    border: #666;
}

.sndIfo-1 .rw-1 p {
    padding: 0.5rem 0px;
    margin: 0px;
}

.sndIfo-1 .ft-1 {
    margin: 0px;
    padding: 0.5rem;
    display: block;
    text-align: left;
}

.sndIfo-1 .ft-1 small {
    text-align: end;
    float: right;
    color: #666;
}

.sndIfo-1 .cr-1 {
    display: -webkit-box;
}

.sndIfo-1 .cr-1 .card-header {
    width: 20%
}

.sndIfo-1 .cr-1 .card-body {
    width: 80%;

    .col-8 {
        text-align: left
    }
}


.sndIfo-1 .cr-1 .card-header h3 {
    margin: revert;
    text-align: center;
    font-weight: bold;
    color: #321fdb;
}

.sndIfo-1 .cr-2 {
    display: -webkit-box;
}

.sndIfo-1 .cr-2 .card-body {
    padding: 0px;
    width: 80%;

    li {
        border-left: 0px;
        border-right: 0px;
    }
}

.sndIfo-1 .cr-2 .card-header {
    width: 20%;
}

.sndIfo-1 .cr-2 .card-header h4 {
    margin: revert;
    text-align: center;
    font-weight: bold;
    color: #321fdb;
}

//sperate 


// svg
// .pre-1 .tb-3 .rw-2 .p-1 svg{height: -webkit-fill-available;width: -webkit-fill-available;}

.recpt_0 {
    color: #f5c431;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_1 {
    color: #81afb8;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_2 {
    color: #91789e;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_3 {
    color: #70948d;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_4 {
    color: #e69d73;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_5 {
    color: #6c89a6;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_6 {
    color: #aeb86e;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_7 {
    color: #db765c;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_8 {
    color: #b38fb3;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}

.recpt_9 {
    color: #d98298;
    text-transform: uppercase;
    padding: 0.3rem 0px;
}


.recipient_0 {
    background-color: #ffd65b;
    border-color: #f5c431
}

.recipient_1 {
    background-color: #acdce6;
    border-color: #81afb8
}

.recipient_2 {
    background-color: #c0a5cf;
    border-color: #91789e
}

.recipient_3 {
    background-color: #97c9bf;
    border-color: #70948d
}

.recipient_4 {
    background-color: #f7b994;
    border-color: #e69d73
}

.recipient_5 {
    background-color: #c3d5e6;
    border-color: #6c89a6
}

.recipient_6 {
    background-color: #cfdb7f;
    border-color: #aeb86e
}

.recipient_7 {
    background-color: #ff9980;
    border-color: #db765c
}

.recipient_8 {
    background-color: #e6c6e6;
    border-color: #b38fb3
}

.recipient_9 {
    background-color: #ffb3c6;
    border-color: #d98298
}



// recpient
.recpint-1 {
    manage: 0px
}

.recpint-1 .fixed-height {
    min-height: 870px;
    height: 870px;
}

.recpint-1 .col-md-2 {
    padding-right: 10px;
    box-sizing: content-box;
    overflow-y: scroll !important;
    /* Add the ability to scroll */
}

.recpint-1 .col-md-2::-webkit-scrollbar {
    display: none !important;
}

.recpint-1 .col-md-2 {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.recpint-1 .col-md-2 {
    padding: 0px !important;
}

.recpint-1 .col-md-2 .hed-1 {
    background: #4D148C;
    border: 0px;
    border-radius: 0px;
    padding: 1rem;
    padding-bottom: 4.5rem;
}

.recpint-1 .col-md-2 .card-header .sndi-2 h4 {
    text-align: end;
    color: white;
    border-right: 3px solid;
    margin-top: 5px;
}

.recpint-1 .col-md-2 .card-header .sndi-2 .bb-1 {
    border-bottom: 1px solid white;
    border-radius: 10px;
    padding-bottom: 7px;
}

.recpint-1 .col-md-2 .card-header .sndi-2 .snd-1 {
    font-weight: bold;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
}

.recpint-1 .col-md-2 .card-header .sndi-2 .snd-1 h5 {
    color: #eeeeee;
    text-transform: uppercase;
    margin-bottom: 3px;
}

.recpint-1 .col-md-2 .card-header .sndi-2 .snd-1 h6 {
    color: lightgray;
    text-transform: lowercase;
    font-size: 15px;
}

.recpint-1 .col-md-2 .card-header .sndi-2 small {
    font-size: 12px;
    color: lightgray;
}

.recpint-1 .col-md-2 .bg-gradient-secondary {
    margin: 0px;
    border: 0px !important;
}

.recpint-1 .col-md-2 .bg-gradient-secondary .card-header {
    font-size: 20px;
    font-weight: bold;
    color: #FF6600;
}

.recpint-1 .col-md-2 .bg-gradient-secondary .card-header h5 {
    color: #FF6600;
}

.recpint-1 .col-md-2 .bg-gradient-secondary .card-body {
    font-size: 14px !important;
    padding: 0.5rem !important;
}

.recpint-1 .col-md-2 .bg-gradient-secondary .card-body .rc-3 {
    background: #4D148C !important;
    color: white;
}

.recpint-1 .col-md-2 .bg-gradient-secondary .lb-4 {
    text-transform: uppercase;
    padding: 0.1rem 0.5rem;
    font-weight: bold;
}

.recpint-1 .col-md-2 .bg-gradient-secondary .form-control:disabled,
.form-control[readonly] {
    background: white;
}

.recpint-1 .col-md-2 .sts-1 {
    background: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 1px 5px;
    padding: 0px 0px 5px;
}

.recpint-1 .col-md-2 .sts-1 h4 {
    margin-bottom: 2px;
    text-align: center;
}

.recpint-1 .col-md-2 .sts-1 h5 {
    margin: 0px auto 2px;
    text-transform: uppercase;
    padding-top: 3px;
    padding-left: 0px;
    padding-right: 0px
}

.recpint-1 .col-md-2 hr {
    margin: 0px;
    border: 2px solid white;
}

.recpint-1 .col-md-2 .crd-2 {
    border: 0px;
    border-radius: 0px;
    background: #4D148C;
    margin: 0px;
}

.recpint-1 .col-md-2 .crd-2 .card-body {
    padding: 0.3rem;
}

.recpint-1 .col-md-2 .crd-2 .card {
    margin: 0.5rem;
    margin-top: 1rem;
}

.recpint-1 .col-md-2 .crd-2 .card-body .p-0:hover {
    text-decoration: none;
    font-weight: bold;
}

.recpint-1 .col-md-2 .crd-2 .card-body .form-control:disabled,
.form-control[readonly] {
    background: white;
}

.recpint-1 .col-md-2 .crd-2 .card-body .ls-1 .list-group-item {
    padding: 0.5rem 0.8rem;
}

.recpint-1 .col-md-2 .crd-2 .card-body .ls-1 .list-group-item .col {
    padding: 0px 10px;
}

.recpint-1 .col-md-2 .crd-2 .card-body .ls-1 .list-group-item .col-md-10 {
    cursor: pointer;
    margin-top: 1px;
}

.recpint-1 .col-md-2 .messageDocCon {
    overflow: hidden;
    height: 180px;
    max-height: 180px;
}

.recpint-1 .col-md-2 .privateMessageCon {
    overflow: hidden;
    height: 260px;
    max-height: 260px;
}

.recpint-1 .col-md-2 #messageDoc {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    box-sizing: content-box;
    overflow-y: scroll !important;
    /* Add the ability to scroll */
}

.recpint-1 .col-md-2 #messageDoc::-webkit-scrollbar {
    display: none !important;
}

.recpint-1 .col-md-2 #messageDoc {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.recpint-1 .col-md-2 #privateMessage {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    box-sizing: content-box;
    overflow-y: scroll !important;
    /* Add the ability to scroll */
}

.recpint-1 .col-md-2 #privateMessage::-webkit-scrollbar {
    display: none !important;
}

.recpint-1 .col-md-2 #privateMessage {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.recpint-1 .col-md-2 .requiredElementListCon {
    overflow: hidden;
    height: 285px;
    max-height: 285px;
}

.recpint-1 .col-md-2 #requiredElementList {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    box-sizing: content-box;
    overflow-y: scroll !important;
    /* Add the ability to scroll */
}

.recpint-1 .col-md-2 #requiredElementList::-webkit-scrollbar {
    display: none !important;
}

.recpint-1 .col-md-2 #requiredElementList {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}




.recpint-1 .col-md-2 .fot-1 {
    background: #4D148C;
    border: 0px;
    border-radius: 0px;
    cursor: pointer;
}

.recpint-1 .col-md-2 .fot-1 .sts-1 h4 {
    margin: 0px auto;
    font-weight: bold;
}

.recpint-1 .opcty {
    opacity: 0.5;
}

.recpint-1 .col-md-10 {
    padding: 0px !important;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .listItem {
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    padding: 0.5rem;
    text-align: center;
    display: grid !important;
    text-transform: uppercase;
    border: 2px solid green;
    border-radius: 5px;
    color: black;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .listItem .svg-inline--fa {
    font-size: 1rem;
    margin: 2px auto;
    width: 2rem;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_0 {
    background: #f5c431;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_1 {
    background: #81afb8;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_2 {
    background: #91789e;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_3 {
    background: #70948d;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_4 {
    background: #e69d73;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_5 {
    background: #6c89a6;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_6 {
    background: #aeb86e;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_7 {
    background: #db765c;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_8 {
    background: #b38fb3;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_9 {
    background: #d98298;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .fieldrequired {
    border: 2px solid red;
    border-radius: 5px;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_0:hover {
    cursor: pointer;
    border: 3px solid #f5c431 !important;
    color: #f5c431 !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_1:hover {
    cursor: pointer;
    border: 3px solid #81afb8 !important;
    color: #81afb8 !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_2:hover {
    cursor: pointer;
    border: 3px solid #91789e !important;
    color: #91789e !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_3:hover {
    cursor: pointer;
    border: 3px solid #70948d !important;
    color: #70948d !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_4:hover {
    cursor: pointer;
    border: 3px solid #e69d73 !important;
    color: #e69d73 !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_5:hover {
    cursor: pointer;
    border: 3px solid #6c89a6 !important;
    color: #6c89a6 !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_6:hover {
    cursor: pointer;
    border: 3px solid #aeb86e !important;
    color: #aeb86e !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_7:hover {
    cursor: pointer;
    border: 3px solid #db765c !important;
    color: #db765c !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_8:hover {
    cursor: pointer;
    border: 3px solid #b38fb3 !important;
    color: #b38fb3 !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .recpt_9:hover {
    cursor: pointer;
    border: 3px solid #d98298 !important;
    color: #d98298 !important;
    box-sizing: border-box;
    background-color: #fff;
    //font-size: 14px;
    //font-weight: bold;
}


.recpint-1 .col-md-10 .viewer-annotation-layer .replace {
    border: 0px;
    padding: 0.2rem;
    font-size: 16px;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .replaceDate {
    border: 0px;
    padding: 0.2rem;
    font-size: 16px;
    margin-left: 10px;
}

.ls-3 .mra-1 {
    align-self: center;
    font-weight: bold;
}

.ls-3 .mra-1 .form-check {
    padding-bottom: 1.3rem;
}

.ls-5 .mra-1 {
    align-self: center;
    font-weight: bold;
}

.ls-5 .mra-1 .form-check {
    padding-bottom: 1.3rem;
}

.ls-6 .mra-1 {
    align-self: center;
    font-weight: bold;
}

.ls-6 .mra-1 .form-check {
    padding-bottom: 1.3rem;
}

.selectStampRecpt {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0.3rem;
    outline: 0;
    background-color: #fff;
    border-color: rgba(0, 0, 21, 0.2);
}

.selectInitialRecpt {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0.3rem;
    outline: 0;
    background-color: #fff;
    border-color: rgba(0, 0, 21, 0.2);
}

.recpint-1 .col-md-10 .viewer-annotation-layer .replaceSig {
    padding: 0px;
    border: 0px;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .replaceInitial {
    padding: 0px;
    border: 0px;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .sig-2 .sig-container {
    padding: 0px;
    height: 50px;
    width: max-content !important;
    position: inherit;
}

.recpint-1 .col-md-10 .viewer-annotation-layer .sig-2-title-bottom,
.sig-2-title-top {
    left: 25px;
    line-height: 15px
}

;

.recpint-1 .col-md-10 .viewer-annotation-layer .replaceStamp {
    padding: 0px;
    border: 0px;
}


.recpint-1 .col-md-2 .listItem {
    cursor: pointer;
    margin: 0.3rem;
    display: flex;
    color: black;
    border: 2px solid;
    border-radius: 5px;
    font-weight: bold;
    padding: 0px;
    padding-top: 10px;
    height: 2.6rem;
    max-height: 2.6rem;
}

.recpint-1 .col-md-2 .brD-1 {
    border: 2px solid red;
}

.recpint-1 .col-md-2 .brS-1 {
    border: 2px solid green;
}

.recpint-1 .col-md-2 .listItem .svg-inline--fa {
    font-size: 1.2rem;
    height: 2.3rem;
    width: 2.3rem;
    background: white;
    border: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-top: -10px;
    margin-right: 1rem;
    padding: 0.3rem 0.5rem;
    border-right: 1px solid;
    border-top: 2px solid white;
}

.recpint-1 .col-md-2 .recpt_0 {
    background-color: #ffd65b;
}

.recpint-1 .col-md-2 .recpt_1 {
    background-color: #acdce6;
}

.recpint-1 .col-md-2 .recpt_2 {
    background-color: #c0a5cf;
}

.recpint-1 .col-md-2 .recpt_3 {
    background-color: #97c9bf;
}

.recpint-1 .col-md-2 .recpt_4 {
    background-color: #f7b994;
}

.recpint-1 .col-md-2 .recpt_5 {
    background-color: #c3d5e6;
}

.recpint-1 .col-md-2 .recpt_6 {
    background-color: #cfdb7f;
}

.recpint-1 .col-md-2 .recpt_7 {
    background-color: #ff9980;
}

.recpint-1 .col-md-2 .recpt_8 {
    background-color: #e6c6e6;
}

.recpint-1 .col-md-2 .recpt_9 {
    background-color: #ffb3c6;
}


// signature modal
.recpt-sig-1 {
    .input-group-text {
        background-color: #FF6600;
        color: white;
    }

    .form-control {
        color: #4D148C;
    }

    .card-header {
        padding: 0.7rem 1rem;
    }

    .h-tab {
        padding-top: 12px;
        margin: 0px;
        background: #4D148C;

        .nav-tabs {
            background: #4D148C;

            .nav-item a {
                color: white;
            }

            .nav-item .active {
                color: #4D148C;
                font-weight: bold;
            }
        }
    }

    .sigm-1 .card-body {
        padding-top: 1rem;

        .tb-1 {
            height: 155px !important;

            .sig-2 {
                margin: 0px;
                height: 60px;

                .sig-container {
                    height: 60px;
                }

                .sig-container input {
                    font-size: 24px !important;
                }
            }

            .form-check-input:checked {
                background-color: #FF6600;
                border-color: #FF6600;
            }
        }

        .tb-2 {
            .sel-1 {
                padding-top: 0px;
                padding-left: 15px;
            }

            .rw-1 {
                justify-content: center;
                padding: 1rem 0px;
            }

            .rw-1 .btn {
                margin: 0.5rem 0.3rem;
                width: 15%;
            }

            .cl-1 {
                width: 100% !important;
                margin-bottom: 10px;
            }
        }

        .tb-3 {
            .chooseFileButton {
                background: #FF6600;
            }
        }
    }
}

// stamp modal
.recpt-stmp-1 {
    .drw-2 {
        align-self: center;
    }

    .col-md-6 {
        .fam-4 {
            margin-top: 1rem;
        }

        .lb-1 {
            float: left;
            padding: 0.5rem;
            color: #4D148C;
        }

        .lb-2 {
            float: left;
            padding: 0.5rem;
            color: #4D148C;
        }

        .c-switch-slider {
            margin: 0.5rem 0px;
        }

        .c-switch-slider::before {
            background: #FF6600;
        }

        .fileUploader {
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 0.5rem;
            height: 200px !important;
            width: 200px !important;

            .fileContainer {
                margin: 0px;
            }
        }

        .chooseFileButton {
            background-color: #4D148C;
            color: white;
        }

        .stampUpload {
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 0.5rem;
            height: 200px !important;
            width: 200px !important;
        }
    }
}

// recipient register
.app-zoom .recpt_register_modal {
    .time-icn-close-modal svg {
        margin-left: -1px;
    }

    .private-col-bottom {
        background-color: white;

        .cancel-btn {
            color: #666666;
        }

        .done-btn {
            background: #4D148C;
            margin-right: auto !important;
        }

        .done-btn:hover {
            background: #666666;
        }
    }
}

.temp-1 {
    margin: 0rem 5%;
    padding: 0rem 2rem;

    .dataTables_length {
        margin-top: 0.5rem;
    }

    .dataTables_wrapper .dataTables_length select {
        padding: 5px 10px !important;
        margin: 0px 5px;
    }

    .dataTables_wrapper .dataTables_filter input {
        margin-left: 0.5rem;
        padding: 5px 10px;
    }

    .card-header .ml-a {
        background: #4D148C;
        font-weight: bold;
        width: 21%;
        margin-left: auto;
    }

    #TemplateTable {
        .card-header {
            background: darkgray;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
            padding-top: 10px;
            padding-bottom: 10px;

            th {
                padding: 15px;
            }
        }

        .card-body {
            .dataTables_wrapper .dataTables_paginate {
                padding-top: 0.5rem;
            }

            .dataTables_wrapper .dataTables_paginate .paginate_button.current,
            .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
                border-radius: 50%;
                background: darkgray !important;
                color: white !important;
            }

            .dataTables_wrapper .dataTables_paginate span a {
                border-radius: 50%;
            }

            td {
                padding: 0.7rem;
            }

            .bt-1 {
                padding: 0.3rem;
            }

            .bt-1 button {
                padding: 0.1rem 1rem;
                background: #4D148C;
                color: white;
            }

            .atn-1 {
                text-align: center;

                svg {
                    width: 1rem;
                    margin: 0px 0.5rem;
                }

                .fa-w-18 {
                    height: 22px;
                }

                .fa-w-14 {
                    height: 20px;
                }
            }
        }

    }
}


.pub-1 {

    .hed-1 {
        background: #4D148C;
        color: white;
        border: 1px solid;
        border-radius: 10px;
        padding: 0.5rem;
        margin: 0.5rem 0.2rem 0.2rem;

        h4 {
            margin: 0px;
            padding: 0.2rem;
            color: white;
            font-weight: bold;
        }

        p {
            color: lightgray;
            margin: 0px;
            padding: 0.2rem 0.2rem 0.2rem 1rem;
        }
    }

    .card-body {
        .up-1 {
            padding: 0.5rem 0px;
            border: 1px solid lightgray;
            border-radius: 10px;
            margin: 0.6rem 0px;
        }
    }

    form {
        width: 100%;

        label {
            font-weight: bold;
        }

        small {
            color: red;
        }

        .lb-2 {
            display: flex;
            margin: 0px;

            label {
                margin: 0px;
            }

            p {
                margin: 0px;
                margin-left: auto;
                color: gray;
            }
        }
    }

    .ls-1 .list-group-item {
        .col {
            align-self: center;
        }

        .col-5 {
            display: grid;
            font-size: 18px;
            line-height: 1.2;

            p {
                text-transform: lowercase;
                margin: 0px;
                color: #4D148C;
            }

            small {
                color: #636f83;
                text-transform: capitalize;
            }
        }

        .btn {
            border: 1px solid;
            background: darkgray;
            color: white;
            padding: 0.5rem 1.5rem;
        }
    }

}





.c-loading {
    body {
        background: #050505;
        color: #ddd;
        font-weight: 300;
        font-family: 'Source Sans Pro', sans-serif;
    }

    body>.container {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 40;
    }

    body::before {
        background-size: 100% 100%;
        height: 1px;
        top: 1px;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.74)), color-stop(50%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.74) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
    }

    @keyframes pulse {
        0% {
            height: 0%;
            box-shadow: 0 0 4px rgba(0, 0, 0, 1.0);
        }

        20% {
            background: #4D148C;
            box-shadow: 0 0 8px rgba(60, 75, 100, 0.8);
            height: 100%;
        }

        50% {
            background: #FF6600;
            box-shadow: 0 0 8px rgba(255, 255, 255, 0.0);
        }

        80% {
            background: #4D148C;
            height: 100%;
            box-shadow: 0 0 8px rgba(255, 255, 255, 0.0);
        }

        100% {
            height: 0%;
            box-shadow: 0 0 4px rgba(0, 0, 0, 1.0);
        }
    }

    .loader>ul {
        list-style: none;
        position: absolute;
        // width:120px;
        // height:120px;
        left: 48%;
        top: 40%;
        // margin:-60px -80px;
    }

    .loader li {
        width: 4px;
        height: 30px;
        border-radius: 2px;
        position: absolute;
        left: 50%;
        top: 10px;
        margin-left: -2px;
        transform-origin: 2px 50px;
        box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1);
    }

    .loader li div {
        border-radius: 2px;
        width: 4px;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        bottom: 0px;
        left: 0px;
        animation: pulse 6s normal infinite;
    }

    ul>li:nth-of-type(1) {
        transform: rotate(0deg);
        box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
    }

    ul>li:nth-of-type(1)>div {
        animation-delay: 100ms;
    }

    ul>li:nth-of-type(2) {
        transform: rotate(30deg);
    }

    ul>li:nth-of-type(2)>div {
        animation-delay: 200ms;
    }


    ul>li:nth-of-type(3) {
        transform: rotate(60deg);
    }

    ul>li:nth-of-type(3)>div {
        animation-delay: 300ms;
    }


    ul>li:nth-of-type(4) {
        transform: rotate(90deg);
    }

    ul>li:nth-of-type(4)>div {
        animation-delay: 400ms;
    }


    ul>li:nth-of-type(5) {
        transform: rotate(120deg);
    }

    ul>li:nth-of-type(5)>div {
        animation-delay: 500ms;
    }


    ul>li:nth-of-type(6) {
        transform: rotate(150deg);
    }

    ul>li:nth-of-type(6)>div {
        animation-delay: 600ms;
    }


    ul>li:nth-of-type(7) {
        transform: rotate(180deg);
        box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1);
    }

    ul>li:nth-of-type(7)>div {
        animation-delay: 700ms;
    }


    ul>li:nth-of-type(8) {
        transform: rotate(210deg);
        box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
    }

    ul>li:nth-of-type(8)>div {
        animation-delay: 800ms;
    }


    ul>li:nth-of-type(9) {
        transform: rotate(240deg);
        box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
    }

    ul>li:nth-of-type(9)>div {
        animation-delay: 900ms;
    }


    ul>li:nth-of-type(10) {
        transform: rotate(270deg);
        box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
    }

    ul>li:nth-of-type(10)>div {
        animation-delay: 1000ms;
    }



    ul>li:nth-of-type(11) {
        transform: rotate(300deg);
        box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
    }

    ul>li:nth-of-type(11)>div {
        animation-delay: 1100ms;
    }


    ul>li:nth-of-type(12) {
        transform: rotate(330deg);
        box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
    }

    ul>li:nth-of-type(12)>div {
        animation-delay: 1200ms;
    }

    .loader::after {
        // background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.03) 0%, rgba(255,255,255,0.03) 49%, rgba(255,255,255,0.02) 50%, rgba(255,255,255,0.02) 100%);
        // background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(255,255,255,0.03)), color-stop(49%,rgba(255,255,255,0.03)), color-stop(50%,rgba(255,255,255,0.02)), color-stop(100%,rgba(255,255,255,0.02)));
        // background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.03) 0%,rgba(255,255,255,0.03) 49%,rgba(255,255,255,0.02) 50%,rgba(255,255,255,0.02) 100%);
        // background: -o-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.03) 0%,rgba(255,255,255,0.03) 49%,rgba(255,255,255,0.02) 50%,rgba(255,255,255,0.02) 100%);
        // background: -ms-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.03) 0%,rgba(255,255,255,0.03) 49%,rgba(255,255,255,0.02) 50%,rgba(255,255,255,0.02) 100%);
        // background: radial-gradient(ellipse at center,  rgba(255,255,255,0.03) 0%,rgba(255,255,255,0.03) 49%,rgba(255,255,255,0.02) 50%,rgba(255,255,255,0.02) 100%);
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: 200% 240%;
        top: 0px;
        left: 0px;
        z-index: 4;
        content: ' ';
    }
}

.context-menu-sign {
    position: fixed;
    z-index: 10000;
    width: 150px;
    background: #1b1a1a;
    border-radius: 5px;
    display: none;
}

.context-menu-sign.visible {
    display: block;
}

.context-menu-sign .delete-item {
    padding: 5px 10px;
    font-size: 15px;
    color: #eee;
    cursor: pointer;
    border-radius: inherit;
}

.context-menu-sign .delete-item:hover {
    background: #343434;
}

.context-menu-sign .required-item {
    padding: 2px 10px;
    font-size: 15px;
    color: #eee;
    cursor: pointer;
    border-radius: inherit;
}

.context-menu-sign .required-item:hover {
    background: #343434;
}

.arrow {
    //position: relative;
    //bottom: -2rem;
    //left: 50%;
    //margin-left:-20px;
    font-size: medium;
    font-weight: bold;
    position: absolute;
    width: 120px;
    height: 40px;
    background-size: contain;
}

.arrow:before {
    position: absolute;
    font-family: 'FontAwesome';
    /*top: 0;
    left: 10px;*/
    content: "\f060  Click Here";
}

#scrollToNextDiv {
    position: absolute;
    left: 16.6%;
    width: 120px;
    z-index: 2000;
    top:12%;
}

/*.bounce {
  animation: bounce 2s infinite;
}*/

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

/* right bounce */
@-webkit-keyframes bounceRight {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    40% {
        -webkit-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    60% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px);
    }
}

@-moz-keyframes bounceRight {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }

    40% {
        transform: translateX(-30px);
    }

    60% {
        transform: translateX(-15px);
    }
}

@keyframes bounceRight {

    0%,
    20%,
    50%,
    80%,
    100% {
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    40% {
        -ms-transform: translateX(-30px);
        transform: translateX(-30px);
    }

    60% {
        -ms-transform: translateX(-15px);
        transform: translateX(-15px);
    }
}

/* /right bounce */

@-webkit-keyframes bounceLeft {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    40% {
        -webkit-transform: translateX(30px);
        transform: translateX(30px);
    }

    60% {
        -webkit-transform: translateX(15px);
        transform: translateX(15px);
    }
}

@-moz-keyframes bounceLeft {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(0);
    }

    40% {
        transform: translateX(30px);
    }

    60% {
        transform: translateX(15px);
    }
}

@keyframes bounceLeft {

    0%,
    20%,
    50%,
    80%,
    100% {
        -ms-transform: translateX(0);
        transform: translateX(0);
    }

    40% {
        -ms-transform: translateX(30px);
        transform: translateX(30px);
    }

    60% {
        -ms-transform: translateX(15px);
        transform: translateX(15px);
    }
}

/* /left bounce */


/* assign bounce */
.bounce {
    -webkit-animation: bounceLeft 2s infinite;
    animation: bounceLeft 2s infinite;
}